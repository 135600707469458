import React from "react";

import { motion, AnimatePresence } from "framer-motion";

import flagFR from "../../../assets/img/FR_flag.png";
import flagEN from "../../../assets/img/UK_flag.png";

import { useGlobalStatesContext } from "../../Context/GlobalStates";

const LanguageComponent = ({ webSite, nameClass }) => {
  const {
    isLoading,
    languageInput,
    setLanguageInput,
    languageWebSite,
    setLanguageWebSite,
    setRefreshLanguageOpacity,
  } = useGlobalStatesContext();

  const fadeTextOnChangeLanguage = () => {
    if (!isLoading) {
      setRefreshLanguageOpacity(0);
      setTimeout(() => {
        setRefreshLanguageOpacity(1);
      }, 500);
    }
  };

  return (
    <div
      className={`${
        nameClass ? "backoffice-" : ""
      }language-selection-container`}>
      <div
        className={`
        ${nameClass ? "backoffice-" : ""}flag-wrapper
        `}>
        <AnimatePresence initial={false}>
          <motion.div
            animate={
              (!nameClass && languageWebSite === "FR") ||
              (nameClass && languageInput === "FR")
                ? {
                    scale: 1.2,
                  }
                : {
                    scale: 1,
                  }
            }
            transition={{ duration: 0.8 }}
            className={`
        ${nameClass ? "backoffice-" : ""}flag-picture-container
        `}>
            <img
              src={flagFR}
              style={{ cursor: "pointer" }}
              alt="drapeau français"
              onClick={() => {
                if (!webSite) {
                  setLanguageInput("FR");
                } else {
                  if (languageWebSite !== "FR") {
                    fadeTextOnChangeLanguage();
                    setTimeout(() => {
                      setLanguageWebSite("FR");
                    }, 300);
                  }
                }
              }}
            />
          </motion.div>
        </AnimatePresence>
        <AnimatePresence initial={false}>
          <motion.div
            animate={
              (!nameClass && languageWebSite === "EN") ||
              (nameClass && languageInput === "EN")
                ? {
                    scale: 1.2,
                  }
                : {
                    scale: 1,
                  }
            }
            transition={{ duration: 0.8 }}
            className={`
            ${nameClass ? "backoffice-" : ""}flag-picture-container
            `}>
            <img
              src={flagEN}
              style={{ cursor: "pointer" }}
              alt="drapeau anglais"
              onClick={() => {
                if (!webSite) {
                  setLanguageInput("EN");
                } else {
                  if (languageWebSite !== "EN") {
                    fadeTextOnChangeLanguage();
                    setTimeout(() => {
                      setLanguageWebSite("EN");
                    }, 300);
                  }
                }
              }}
            />
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default LanguageComponent;
