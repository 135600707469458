import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { sanitize } from "dompurify";

import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import LanguageSwapOpacityWrapper from "../../Shared/components/languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";
import IconComponent from "../../Shared/components/IconComponent/IconComponent";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";
import { requestHandle } from "../../Shared/Utils/RequestFunctions";

const RealisationSingle = () => {
  const { isLoading, setIsLoading, setInformationMessage, languageWebSite } =
    useGlobalStatesContext();

  const navigate = useNavigate();
  // const { realisation_id } = useParams();
  const singleID = useLocation().pathname.split("/").pop();

  const [modalePicture, setModalePicture] = useState(false);

  const [realisationData, setRealisationData] = useState();
  const [indexSelectedGallery, setIndexSelectedGallery] = useState(0);

  const fetchSingleRealisationData = async () => {
    setIsLoading(true);
    const responseFetchSingleRealisationData = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "POST",
      "realisation/client/single",
      { realisation_id: singleID }
    );
    if (
      responseFetchSingleRealisationData &&
      responseFetchSingleRealisationData.statut === "OK"
    ) {
      let realisationDataFormated = responseFetchSingleRealisationData.message;

      if (
        realisationDataFormated["single_data"] &&
        realisationDataFormated["single_data"]["gallery"]
      ) {
        realisationDataFormated["single_data"]["gallery"] =
          realisationDataFormated["single_data"]["gallery"].split(",");

        realisationDataFormated["single_data"]["gallery"].push(
          realisationDataFormated["single_data"]["realisation_picture"]
        );
      } else {
        realisationDataFormated["single_data"]["gallery"] = [
          realisationDataFormated["single_data"]["realisation_picture"],
        ];
      }

      setRealisationData(realisationDataFormated);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleRealisationData();
  }, [singleID]);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="single-realisation-main">
      {realisationData && realisationData["single_data"] && (
        <>
          {modalePicture && (
            <div className="modale-picture">
              <IconComponent
                icon="close-filled"
                size={20}
                color="var(--black)"
                class={"close-modale"}
                style={{ cursor: "pointer" }}
                onClick={() => setModalePicture(false)}
              />
              <div className="modale-picture-container">
                <img
                  src={
                    realisationData["single_data"]["gallery"][
                      indexSelectedGallery
                    ]
                  }
                  alt=""
                />
              </div>
            </div>
          )}
          {/* <LanguageSwapOpacityWrapper
            backgroundImage={
              realisationData["single_data"]["realisation_picture"]
            }
            nameClass={"realisation-title-image"}>
            <div className="realisation-title-container">
              <h1>
                {languageWebSite === "FR"
                  ? realisationData["single_data"]["title_fr"]
                  : realisationData["single_data"]["title_en"]
                  ? realisationData["single_data"]["title_en"]
                  : realisationData["single_data"]["title_fr"]}
              </h1>
              {realisationData["single_data"]["content_fr"] && (
                <p className="referencement-text">
                  {realisationData["single_data"]["content_fr"]}
                </p>
              )}
            </div>
          </LanguageSwapOpacityWrapper> */}

          <div
            className="single-realisation-container"
            style={modalePicture ? { opacity: 0.5 } : {}}>
            <div className="yellow-polygon"></div>
            {realisationData["single_data"]["gallery"] &&
              realisationData["single_data"]["gallery"].length > 0 && (
                <div className="realisation-gallery-container">
                  <div
                    className="selected-gallery-container"
                    onClick={() => {
                      setModalePicture(true);
                    }}>
                    <img
                      src={
                        (indexSelectedGallery &&
                          realisationData["single_data"]["gallery"][
                            indexSelectedGallery
                          ]) ||
                        (indexSelectedGallery === 0 &&
                          realisationData["single_data"]["gallery"][
                            indexSelectedGallery
                          ])
                          ? realisationData["single_data"]["gallery"][
                              indexSelectedGallery
                            ]
                          : !indexSelectedGallery &&
                            realisationData["single_data"][
                              "realisation_picture"
                            ]
                          ? realisationData["single_data"][
                              "realisation_picture"
                            ]
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div className="miniatures-list-container">
                    {realisationData["single_data"]["gallery"].map(
                      (picture, index) => {
                        return (
                          <div
                            onClick={() => setIndexSelectedGallery(index)}
                            key={index}
                            className="realisation-gallery-miniatures">
                            <img src={picture} alt="" />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}

            {realisationData &&
              realisationData["single_data"]["content_fr"] && (
                <LanguageSwapOpacityWrapper
                  nameClass={"realisation-content-container"}>
                  <h1>
                    {languageWebSite === "FR"
                      ? realisationData["single_data"]["title_fr"]
                      : realisationData["single_data"]["title_en"]
                      ? realisationData["single_data"]["title_en"]
                      : realisationData["single_data"]["title_fr"]}
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        languageWebSite === "FR"
                          ? sanitize(
                              realisationData["single_data"]["content_fr"]
                            )
                          : sanitize(
                              realisationData["single_data"]["content_en"]
                            )
                          ? sanitize(
                              realisationData["single_data"]["content_en"]
                            )
                          : sanitize(
                              realisationData["single_data"]["content_fr"]
                            ),
                    }}></div>
                </LanguageSwapOpacityWrapper>
              )}
            <div className="realisation-navigation-container">
              <div className="realisation-navigation-text-icon-container">
                <h3>
                  {languageWebSite === "FR"
                    ? "Réalisation précédente"
                    : "previous project"}
                </h3>

                <IconComponent
                  icon="arrow-left2"
                  size={30}
                  color={
                    realisationData && realisationData["prev_realisation"]
                      ? "rgb(36, 36, 36)"
                      : "lightgray"
                  }
                  style={
                    realisationData &&
                    realisationData["prev_realisation"] && { cursor: "pointer" }
                  }
                  onClick={() =>
                    realisationData &&
                    realisationData["prev_realisation"] &&
                    navigate(
                      `/realisation/${realisationData["prev_realisation"]}`
                    )
                  }
                />
              </div>

              <div className="realisation-navigation-text-icon-container realisation-nav-next">
                <h3>
                  {languageWebSite === "FR"
                    ? "Réalisation suivante"
                    : "next project"}
                </h3>
                <IconComponent
                  icon="arrow-right2"
                  size={30}
                  color={
                    realisationData && realisationData["next_realisation"]
                      ? "rgb(36, 36, 36)"
                      : "lightgray"
                  }
                  style={
                    realisationData &&
                    realisationData["next_realisation"] && { cursor: "pointer" }
                  }
                  onClick={() =>
                    realisationData &&
                    realisationData["next_realisation"] &&
                    navigate(
                      `/realisation/${realisationData["next_realisation"]}`
                    )
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default RealisationSingle;
