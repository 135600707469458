import axios from "axios";

export const requestHandle = async (
  setIsLoading,
  setInformationMessage,
  token,
  axiosMethod,
  routeByCollection,
  values
) => {
  // setIsLoading(true);
  setInformationMessage("");

  let response;
  try {
    let formData;
    if (values) {
      formData = new FormData();

      const keys = Object.keys(values);

      keys.forEach((key) => {
        formData.append(key, values[key]);
      });

      if (values.galleryPictures) {
        values.galleryPictures.map((pic, index) => {
          formData.append(`galleryPic${index}`, pic);
        });
      }
    }

    const url_server = `https://enriquez-services-5626750339aa.herokuapp.com/${routeByCollection}`;

    // const url_server = `http://localhost:4000/${routeByCollection}`;

    response = await axios({
      method: axiosMethod,
      url: url_server,

      data: formData ? formData : null,

      headers: {
        Authorization: token ? token : "",
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    setInformationMessage({
      title: `${
        error.response && error.response.status
          ? `Error ${error.response.status}`
          : error.code
          ? error.code
          : ""
      }`,
      content:
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
  // setIsLoading(false);

  return response?.data;
};

export const sendDataToServer = async (
  setIsLoading,
  setInformationMessage,
  token,
  requestType,
  mongoCollection,
  dataToSend,
  setModale,
  modaleObject
) => {
  let response;

  response = await requestHandle(
    setIsLoading,
    setInformationMessage,
    token,
    requestType,
    mongoCollection,
    dataToSend
  );

  if (response && response.statut === "OK" && modaleObject) {
    setModale(modaleObject);
  }
  return response;
};

export const statutUpdate = async (
  setIsLoading,
  setInformationMessage,
  collection,
  token,
  index,
  state,
  setState
) => {
  const copyStateList = [...state];
  copyStateList[index]["statut"] = !copyStateList[index]["statut"];
  setState(copyStateList);

  const response = await requestHandle(
    setIsLoading,
    setInformationMessage,
    token,
    "PUT",
    collection,
    { _id: copyStateList[index]["_id"], statut: copyStateList[index]["statut"] }
  );

  if (response && response.statut === "OK") {
    setInformationMessage({
      title: "Mis à jour réussie",
      content: "Statut mis à jour",
      messageType: "success",
    });
  } else {
    setInformationMessage({
      title: "Erreur de mis à jour",
      content: "Mis à jour interrompue",
    });
  }
};
