import React, { useState, useEffect } from "react";

import PaginatedRealisation from "../../Shared/components/Pagination/Pagination";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";
import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import LanguageSwapOpacityWrapper from "../../Shared/components/languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";

const RealisationsList = () => {
  const { isLoading, setIsLoading, setInformationMessage, languageWebSite } =
    useGlobalStatesContext();

  const [realisationData, setRealisationData] = useState([]);
  const [filters, setFilters] = useState();

  const [category, setCategory] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  const fetchCategoryToSelect = async () => {
    const responseCategory = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "GET",
      "client/navigation",
      ""
    );
    if (responseCategory && responseCategory.statut === "OK") {
      setCategory(responseCategory.message);
    }
  };

  const fetchRealisationData = async () => {
    setIsLoading(true);

    const responseRealisationData = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "GET",
      "client/realisation",
      ""
    );

    if (responseRealisationData && responseRealisationData.statut === "OK") {
      setCurrentItems(responseRealisationData.message.slice(itemOffset, 20));

      setPageCount(Math.ceil(responseRealisationData.message.length / 20));
      setRealisationData(responseRealisationData.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCategoryToSelect();
    fetchRealisationData();
  }, []);

  return (
    <main id="realisation-container">
      <LanguageSwapOpacityWrapper>
        <h1>
          {languageWebSite === "FR" ? "Nos Réalisations" : "Our Projects"}
        </h1>
        <p>{languageWebSite === "FR" ? "" : "Our Projects"}</p>
      </LanguageSwapOpacityWrapper>
      <div className="filters-container input-group">
        <select
          name="realisation-category"
          id="realisation-category"
          onChange={(e) => {
            setFilters(e.target.value);
            setItemOffset(0);
          }}>
          <option value="">
            {languageWebSite === "FR"
              ? "Choisissez une catégorie pour filtrer les résultats"
              : "Choose a category to filter projects"}
          </option>

          {category &&
            category.length > 0 &&
            category
              .filter((elmt) => elmt["name_fr"] !== "Accueil")
              .map((item) => {
                return (
                  <option key={item["_id"]} value={item["_id"]}>
                    {languageWebSite === "FR"
                      ? item["name_fr"]
                      : item["name_en"]
                      ? item["name_en"]
                      : item["name_fr"]}
                  </option>
                );
              })}
        </select>
      </div>

      <section className="section-realisation">
        <PaginatedRealisation
          isLoading={isLoading}
          currentItems={currentItems}
          setCurrentItems={setCurrentItems}
          itemsPerPage={20}
          items={realisationData.filter((elmt) => {
            if (filters) {
              return elmt["category"] === filters;
            } else {
              return elmt;
            }
          })}
          filters={filters}
          itemOffset={itemOffset}
          setItemOffset={setItemOffset}
          pageCount={pageCount}
          setPageCount={setPageCount}
        />
      </section>
    </main>
  );
};

export default RealisationsList;
