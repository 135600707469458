import { createContext, useState, useContext, useEffect } from "react";

const LoadingErrorContext = createContext();

export const GlobalStatesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [informationMessage, setInformationMessage] = useState("");
  const [modale, setModale] = useState(false);
  const [languageInput, setLanguageInput] = useState("FR");
  const [languageWebSite, setLanguageWebSite] = useState("FR");
  const [innerWidthState, setInnerWidthState] = useState(window.innerWidth);
  const [modalSideMenu, setModalSideMenu] = useState(
    innerWidthState < 768 ? false : true
  );
  const [refreshLanguageOpacity, setRefreshLanguageOpacity] = useState(1);
  const [opacityState, setOpacityState] = useState(1);

  const updateInnerWidthStateHandle = () => {
    if (window.innerWidth < 768) {
      setModalSideMenu(false);
    } else if (window.innerWidth > 768) {
      setModalSideMenu(true);
    }
    setInnerWidthState(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateInnerWidthStateHandle);

    return () => {
      window.removeEventListener("resize", updateInnerWidthStateHandle);
    };
  }, []);

  const GlobalStatesContextValues = {
    isLoading,
    setIsLoading,

    informationMessage,
    setInformationMessage,

    modale,
    setModale,

    languageInput,
    setLanguageInput,

    languageWebSite,
    setLanguageWebSite,

    innerWidthState,
    setInnerWidthState,

    modalSideMenu,
    setModalSideMenu,

    refreshLanguageOpacity,
    setRefreshLanguageOpacity,

    opacityState,
    setOpacityState,
  };

  return (
    <LoadingErrorContext.Provider value={GlobalStatesContextValues}>
      {children}
    </LoadingErrorContext.Provider>
  );
};

export const useGlobalStatesContext = () => {
  return useContext(LoadingErrorContext);
};
