import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import {
  requestHandle,
  sendDataToServer,
} from "../../Shared/Utils/RequestFunctions";

import InputComponent from "../../Shared/components/Form/InputComponent";
import CkEditorComponent from "../../Shared/components/CkEditor/CkEditorComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";

import LanguageComponent from "../../Shared/components/LanguageComponent/LanguageComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";

const SectionCreateUpdate = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    informationMessage,
    setInformationMessage,
    setModale,
    languageInput,
  } = useGlobalStatesContext();

  const isSingleID = useLocation().pathname.split("/").pop();

  const [dataToSend, setDataToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });
  const [categoryToSelect, setCategoryToSelect] = useState([]);

  const validateForm = (state) => {
    const errorsObject = {};
    let isError = false;
    if (state.values) {
      if (!state.values.name_fr && !state.values.no_name) {
        errorsObject.name_fr = "Merci de renseigner ce champ";
        isError = true;
      }

      const copyNavigationData = { ...dataToSend };
      copyNavigationData.errors = errorsObject;
      setDataToSend(copyNavigationData);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendSectionData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateForm(dataToSend)) {
      const copyDataToSend = { ...dataToSend };

      copyDataToSend.values["ref_to_push"] =
        copyDataToSend.values["associated_page"];

      if (
        copyDataToSend.values["services"] &&
        copyDataToSend.values["services"].length <= 0
      ) {
        delete copyDataToSend.values["services"];
      }

      copyDataToSend.values["slug"] = `${dataToSend.values.name_fr
        .toLowerCase()
        .split(" ")
        .filter((elmt) => elmt !== "/")
        .join("-")}`;

      await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        isSingleID !== "create" ? "PUT" : "POST",
        "section",
        dataToSend.values,
        setModale,
        {
          title: "Nouvelle section ajoutée",
          content: "Une nouvelle section a été ajoutée à la liste",
          collection: "section",
          listeName: "sections",
        }
      );
    } else {
      setInformationMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
    setIsLoading(false);
  };

  const fetchSingleData = async () => {
    if (isSingleID && isSingleID !== "create") {
      const responseFetchSingleData = await requestHandle(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "POST",
        "section",
        { single: isSingleID }
      );
      if (responseFetchSingleData && responseFetchSingleData.statut === "OK") {
        const responseObject = responseFetchSingleData.message;
        responseObject["prev_category"] = responseObject["associated_page"];
        setDataToSend((prevState) => {
          return {
            ...prevState,
            values: responseFetchSingleData.message,
          };
        });
      }
    }
    setIsLoading(false);
  };

  const fetchCategoryToSelect = async () => {
    const responseCategory = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "navigation",
      ""
    );
    if (responseCategory && responseCategory.statut === "OK") {
      setCategoryToSelect(responseCategory.message);
    }
  };

  useEffect(() => {
    fetchSingleData();
    fetchCategoryToSelect();
  }, []);

  if (isLoading) return <LoadingComponent />;

  return (
    <main className="backoffice-form-main">
      <h1>
        {isSingleID === "create"
          ? "Créer un nouvel ensemble de services"
          : "Mettre à jour un ensemble de services"}
      </h1>
      <div className="backoffice-form-container">
        {informationMessage && (
          <InformationMessageComponent
            informationMessage={informationMessage}
            setInformationMessage={setInformationMessage}
          />
        )}
        <ModaleComponent goBack={true}>
          <LanguageComponent nameClass={true} />

          <form onSubmit={(e) => sendSectionData(e)}>
            <div className="input-container">
              <InputComponent
                inputType={"checkbox"}
                page={"backoffice-sub-section"}
                label={""}
                objectKey={"robot_checkbox"}
                placeholder={""}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateForm}
              />
              <InputComponent
                inputType={"select"}
                required={languageInput === "FR" ? true : false}
                isError={
                  dataToSend.errors["associated_page"]
                    ? dataToSend.errors["associated_page"]
                    : false
                }
                page={"backoffice-sub-section"}
                label={`Nom de la categorie associée`}
                objectKey={"associated_page"}
                placeholder={`le nom de la catégorie associée`}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateForm}
                arrayOptions={
                  categoryToSelect
                    ? categoryToSelect.filter(
                        (elmt) => elmt["name_fr"] !== "Accueil"
                      )
                    : []
                }
              />

              {dataToSend &&
                dataToSend.values &&
                dataToSend.values.associated_page && (
                  <>
                    <InputComponent
                      // disableCondition={
                      //   dataToSend.values["no_name"] ? true : false
                      // }
                      inputType={"text"}
                      required={languageInput === "FR" ? true : false}
                      isError={
                        languageInput === "FR" &&
                        dataToSend.errors.name_fr &&
                        dataToSend.touched.name_fr
                          ? dataToSend.errors.name_fr
                          : dataToSend.errors.name_en &&
                            dataToSend.touched.name_en &&
                            dataToSend.errors.name_en
                      }
                      page={"backoffice-sub-section"}
                      objectKey={languageInput === "FR" ? "name_fr" : "name_en"}
                      label={`Nom ${
                        languageInput === "FR" ? "français" : "anglais"
                      } de la section dans la barre de navigation`}
                      placeholder={`le nom ${
                        languageInput === "FR" ? "français" : "anglais"
                      }  de la section`}
                      state={dataToSend}
                      setState={setDataToSend}
                      validateFunction={validateForm}
                    />

                    <InputComponent
                      inputType={"text"}
                      isError={
                        languageInput === "FR" &&
                        dataToSend.errors.title_fr &&
                        dataToSend.touched.title_fr
                          ? dataToSend.errors.title_fr
                          : dataToSend.errors.title_en &&
                            dataToSend.touched.title_en &&
                            dataToSend.errors.title_en
                      }
                      page={"backoffice-sub-section"}
                      objectKey={
                        languageInput === "FR" ? "title_fr" : "title_en"
                      }
                      label={`Titre ${
                        languageInput === "FR" ? "français" : "anglais"
                      } de la section`}
                      placeholder={`le titre ${
                        languageInput === "FR" ? "français" : "anglais"
                      }  de la présentation`}
                      state={dataToSend}
                      setState={setDataToSend}
                      validateFunction={validateForm}
                    />

                    {/* <InputComponent
                    inputType={"textarea"}
                    isError={
                      languageInput === "FR" &&
                      dataToSend.errors.content_fr &&
                      dataToSend.touched.content_fr
                        ? dataToSend.errors.content_fr
                        : dataToSend.errors.content_en &&
                          dataToSend.touched.content_en &&
                          dataToSend.errors.content_en
                    }
                    page={"backoffice-sub-section"}
                    objectKey={
                      languageInput === "FR" ? "content_fr" : "content_en"
                    }
                    label={`Contenu ${
                      languageInput === "FR" ? "français" : "anglais"
                    } de la section`}
                    placeholder={`le contenue ${
                      languageInput === "FR" ? "français" : "anglais"
                    }  de la section`}
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateForm}
                  /> */}

                    {/* <CkEditorComponent
          state={dataToSend}
          setState={setDataToSend}
          objectKey={languageInput === "FR" ? "content_fr" : "content_en"}
        /> */}

                    <CkEditorComponent
                      page={"backoffice-section"}
                      label={`Contenu ${
                        languageInput === "FR" ? "français" : "anglais"
                      } de la présentation des services`}
                      state={dataToSend}
                      setState={setDataToSend}
                      objectKey={
                        languageInput === "FR" ? "content_fr" : "content_en"
                      }
                    />

                    {/* <InputComponent
                    inputType={"picture"}
                    page={"backoffice-sub-section"}
                    label={"Photo de présentation de la section"}
                    objectKey={"section_picture"}
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateForm}
                  /> */}

                    {/* <InputComponent
                    inputType={"picture"}
                    page={"backoffice-sub-section"}
                    label={
                      "Pictogramme de la section pour la barre de navigation"
                    }
                    objectKey={"section_picto"}
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateForm}
                  /> */}
                  </>
                )}
            </div>

            <button type="submit">Envoyer</button>
          </form>
        </ModaleComponent>
      </div>
    </main>
  );
};

export default SectionCreateUpdate;
