import React from "react";

import { Link } from "react-router-dom";

import { useGlobalStatesContext } from "../../Context/GlobalStates";

const BackofficeGoBack = ({ collection, listeName }) => {
  const { setModale } = useGlobalStatesContext();
  return (
    <div className="go-back-container">
      <Link to={"/backoffice/home"} onClick={() => setModale(false)}>
        Accueil administration
      </Link>

      {listeName && collection && (
        <Link
          to={`/backoffice/${collection}/liste`}
          onClick={() => setModale(false)}>
          Liste {listeName}
        </Link>
      )}
    </div>
  );
};

export default BackofficeGoBack;
