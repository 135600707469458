import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../Client/Home/Home.js";
import ClientPage from "../Client/ClientPage/ClientPage.js";

import RealisationsList from "../Client/Realisation/RealisationsList.js";
import RealisationSingle from "../Client/Realisation/RealisationSingle.js";
import Contact from "../Client/Contact/Contact.js";

import Header from "../Shared/components/Header/Header.js";
import Footer from "../Shared/components/Footer/Footer.js";
import TermsAndCondition from "../Client/TermsAndCondition/TermsAndCondition.js";
const ClientRoutes = ({ userSession, setUser }) => (
  <>
    <Header userSession={userSession} setUser={setUser} />

    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/page/:category_slug" element={<ClientPage />} />

      <Route path="/realisations" element={<RealisationsList />} />
      <Route
        path="/realisation/:realisation_ID"
        element={<RealisationSingle />}
      />

      <Route path="/contact" element={<Contact />} />
      <Route path="/termsandcondition" element={<TermsAndCondition />} />
    </Routes>
    <Footer />
  </>
);

export default ClientRoutes;
