import React, { useEffect, useRef } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "@ckeditor/ckeditor5-HomeMade";

import { onChangeFormInput } from "../../Utils/EventFunctions";
import { useGlobalStatesContext } from "../../Context/GlobalStates";
import LanguageSwapOpacityWrapper from "../languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";

const CkEditorComponent = ({
  state,
  setState,
  objectKey,
  label,
  required,
  page,
}) => {
  const { languageInput } = useGlobalStatesContext();

  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef) {
      const editor = editorRef.current.editor;
      if (editor) {
        editor.on("instanceReady", function (evt) {
          editor.setData(
            state.values[objectKey] ? state.values[objectKey] : ""
          );
        });
      }
    }
  }, [languageInput]);

  return (
    <div id="ck-editor-container" className="input-group">
      <LanguageSwapOpacityWrapper nameClass={"label-opacity-wrapper"}>
        {label && (
          <label htmlFor={`form-input-${page}-${objectKey}`}>
            {label} {required ? <span className="required-span">*</span> : ""}
          </label>
        )}
      </LanguageSwapOpacityWrapper>

      <CKEditor
        editor={CustomEditor}
        ref={editorRef}
        config={{
          toolbar: {
            items: [
              "undo",
              "redo",
              "alignment",
              "|",
              "heading",
              "bold",
              "italic",
              "|",
              "link",
              "uploadImage",
              "blockQuote",
              "|",
              "bulletedList",
              "numberedList",
              "outdent",
              "indent",
            ],
            shouldNotGroupWhenFull: true,
          },
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChangeFormInput(objectKey, data, state, setState, () => {});
        }}
      />

      {state.touched[objectKey] && state.errors[objectKey] ? (
        <div className={"form-error-container"}>
          <p>{state.errors[objectKey]}</p>
        </div>
      ) : null}
    </div>
  );
};

export default CkEditorComponent;
