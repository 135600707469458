import React, { useState, useEffect } from "react";

import testOGImage from "../../assets/img/marker.png";

import { sanitize } from "dompurify";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReactStars from "react-stars";
import { motion, AnimatePresence } from "framer-motion";

import presentationVideo from "../../assets/videos/enriquez-video-site.webm";

import partenaire_france_renov_pic from "../../assets/img/partenaire_france_renov.png";
import partenaire_prime_renov from "../../assets/img/partenaire_prime_renov.png";
import partenaire_quali_bois from "../../assets/img/partenaire_quali_bois.png";
import partenaire_quali_pac from "../../assets/img/partenaire_quali_pac.png";
import partenaire_qualibat from "../../assets/img/partenaire_qualibat.png";

import { useNavigate } from "react-router-dom";
import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import LanguageSwapOpacityWrapper from "../../Shared/components/languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";
import IconComponent from "../../Shared/components/IconComponent/IconComponent";
import SEOHelmet from "../../Shared/components/SEOHelmet/SEOHelmet";

const Home = () => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    languageWebSite,
    informationMessage,
    refreshLanguageOpacity,
    innerWidthState,
  } = useGlobalStatesContext();

  const navigate = useNavigate();

  const textToFadeVariants = {
    fade: {
      opacity: refreshLanguageOpacity,
    },
  };

  const [homeData, setHomeData] = useState([]);
  const [realisationsData, setRealisationsData] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);

  const picArray = [
    // partenaire_france_renov_pic,
    // partenaire_prime_renov,
    partenaire_quali_bois,
    partenaire_quali_pac,
    partenaire_qualibat,
  ];

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fetchPageData = async () => {
    setIsLoading(true);
    const responseHomeData = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "POST",
      "client/data",
      { id: "659695f5d33be2cd8705dc53" }
    );
    if (responseHomeData && responseHomeData.statut === "OK") {
      setHomeData(responseHomeData.message);
    }

    const responseRealisation = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "GET",
      "client/realisation"
    );
    if (responseRealisation && responseRealisation.statut === "OK") {
      setRealisationsData(responseRealisation.message);
    }

    const responseReviews = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "GET",
      "client/reviews",
      {}
    );

    if (responseReviews && responseReviews.statut === "OK") {
      setReviewsData(responseReviews.message);
    } else {
      setInformationMessage("");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="container-home">
      {informationMessage && (
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />
      )}
      <div className="presentation-video-container">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          width={"100%"}
          className="presentation-video">
          <source src={presentationVideo} type="video/mp4" />
        </video>
      </div>

      <div className="home-right-section">
        <section>
          {homeData &&
            homeData.sections &&
            homeData.sections[0] &&
            homeData.sections[0]["title_fr"] && (
              <>
                <article>
                  <AnimatePresence>
                    <motion.h1
                      dangerouslySetInnerHTML={{
                        __html:
                          languageWebSite === "FR"
                            ? sanitize(homeData.sections[0]["title_fr"])
                            : sanitize(homeData.sections[0]["title_en"])
                            ? sanitize(homeData.sections[0]["title_en"])
                            : sanitize(homeData.sections[0]["title_fr"]),
                      }}
                      animate={"fade"}
                      variants={textToFadeVariants}
                      transition={{ duration: 0.2 }}></motion.h1>

                    <motion.div
                      dangerouslySetInnerHTML={{
                        __html:
                          languageWebSite === "FR"
                            ? sanitize(homeData.sections[0]["content_fr"])
                            : sanitize(homeData.sections[0]["content_en"])
                            ? sanitize(homeData.sections[0]["content_en"])
                            : sanitize(homeData.sections[0]["content_fr"]),
                      }}
                      animate={"fade"}
                      variants={textToFadeVariants}
                      transition={{ duration: 0.2 }}></motion.div>
                  </AnimatePresence>
                </article>
              </>
            )}
        </section>
      </div>

      {realisationsData && realisationsData.length > 0 && (
        <section className="home-nos-realisations">
          <AnimatePresence>
            <motion.h2
              animate={"fade"}
              variants={textToFadeVariants}
              transition={{ duration: 0.2 }}>
              {languageWebSite === "FR" ? "Nos réalisations" : "Projects"}
            </motion.h2>
          </AnimatePresence>

          <div className="realisations-container">
            {realisationsData.map((realisation, index) => {
              if (
                (innerWidthState < 768 && index <= 5) ||
                (innerWidthState > 768 && index <= 7)
              ) {
                return (
                  <motion.article
                    whileHover={{ scale: 1.05, cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/realisation/${realisation["_id"]}`);
                    }}
                    key={realisation["_id"]}
                    className="home-realisation-card">
                    {realisation["realisation_picture"] && (
                      <img src={realisation["realisation_picture"]} alt="" />
                    )}
                  </motion.article>
                );
              }
            })}
          </div>
        </section>
      )}
      {reviewsData && reviewsData.length > 0 && (
        <section className="home-reviews-section">
          <motion.h2
            animate={"fade"}
            variants={textToFadeVariants}
            transition={{ duration: 0.2 }}>
            {languageWebSite === "FR"
              ? "Ils nous ont fait confiance"
              : "They trusted us"}
          </motion.h2>
          <div className="home-reviews-container">
            <Slider {...settingsSlider}>
              {reviewsData.map((review, index) => {
                if (review.fr.rating > 3) {
                  return (
                    <div>
                      <div key={index} className="review-card">
                        <div className="review-card-header">
                          <div className="author-picture-container">
                            {review.fr.profile_photo_url && (
                              <img src={review.fr.profile_photo_url} alt="" />
                            )}
                          </div>
                          <div>
                            {review.fr.author_name && (
                              <p className="author-name-p">
                                {sanitize(review.fr.author_name)}
                              </p>
                            )}
                            <ReactStars
                              value={review.fr.rating}
                              count={5}
                              size={24}
                              edit={null}
                              color2={"#ffd700"}
                            />
                          </div>
                        </div>

                        <div className="review-card-body fade-out">
                          <LanguageSwapOpacityWrapper
                            nameClass={"overflow-fix"}>
                            {review.fr.text && (
                              <p>
                                &laquo; 
                                <q>
                                  {languageWebSite === "FR"
                                    ? sanitize(review.fr.text)
                                    : sanitize(review.en.text)
                                    ? sanitize(review.en.text)
                                    : sanitize(review.fr.text)}
                                </q>
                                 &raquo;
                              </p>
                            )}
                          </LanguageSwapOpacityWrapper>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <div>
                <div className="review-card">
                  <div className="review-card-header">
                    <div className="author-picture-container">
                      {/* <img src={review.fr.profile_photo_url} alt="" /> */}
                      <IconComponent
                        icon="user"
                        size={30}
                        color="rgb(36, 36, 36)"
                      />
                    </div>
                    <div>
                      <p className="author-name-p">Votre note</p>
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        to={"https://g.page/r/CTlTsvzRQqquEBM/review"}>
                        <ReactStars count={5} size={24} color2={"#ffd700"} />
                      </Link>
                    </div>
                  </div>

                  <div className="review-card-body">
                    <p>
                      Votre opinion compte ! Aidez-nous à améliorer votre
                      expérience en nous attribuant une note sur 5 étoiles
                      ci-dessus. Nous travaillons constamment pour vous offrir
                      le meilleur service possible.
                    </p>
                    <p>Merci pour votre contribution!</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      )}

      {picArray && picArray.length > 0 && (
        <section className="home-partenaires">
          {picArray.map((pic, index) => {
            return (
              <div
                key={`partenaire-home-${index}`}
                className="home-partenaires-picture-container">
                <img src={pic} alt="" />
              </div>
            );
          })}
        </section>
      )}
    </main>
  );
};

export default Home;
