import React from "react";
import { Helmet } from "react-helmet-async";

// import SEOHelmet from "../../Shared/components/SEOHelmet";
const TermsAndCondition = () => {
  return (
    <main className="terms-conditions container">
      <Helmet>
        <script src="https://enriquezservices.matomo.cloud/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=1"></script>
      </Helmet>
      {/* <SEOHelmet title={"Conditions générales"} /> */}

      <h1>Mentions légales</h1>
      <div id="matomo-opt-out"></div>

      <h2>Éditeur et concepteur du site</h2>
      <h3>Cent Commentaire</h3>
      <p>
        SARL au capital de 1 000 euros, enregistrée au RCS de Bordeaux, sous le
        numéro 978 632 628 00019.
        <br /> Adresse: 11 route de Massugas, 33790 Pellegrue.
        <br />
        Mail: contact@centcommentaire.fr <br />
        Téléphone: 06 08 87 58 37
      </p>
      <h2>Propriété du site</h2>
      <p>
        Le site www.enriquezservices.fr est la propriété exclusive de la SARL
        ENRIQUEZ CESAR, inscrite au RCS d’Agen sous le numéro 352 109 862.
        <br />
        Adresse : 700 avenue Alfred Court, 47800 Miramont-de-Guyenne <br />
        Mail : enriquez@outlook.fr
      </p>
      <h2>Directeur de la publication</h2>
      <p>
        Marielle Guiet
        <br />
        Mail: enriquez@outlook.fr <br />
        Téléphone: 05 53 88 09 55
      </p>
      <h2>Hébergement</h2>
      <h3>o2switch</h3>
      <p>
        SARL au capital de 100 000 euros, enregistrée au RCS de
        Clermont-Ferrand, sous le numéro 510 909 807 00024. <br />
        Adresse: 222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand
      </p>
      <h2>Propriété intellectuelle</h2>
      <p>
        L’ensemble de ce site relève de la législation française et
        internationale sur le droit d’auteur et la propriété intellectuelle .
      </p>
      <p>
        Tous les droits de reproduction sont réservés, y compris pour les
        représentations iconographiques et photographiques, ainsi que les
        contenus rédactionnels.
      </p>
      <p>
        L’ensemble des données, y compris les logos, slogans et photos figurant
        sur les pages de ce site constituent, ensemble, une œuvre protégée par
        les lois en vigueur sur la propriété intellectuelle, dont Enriquez
        Ménager ou ses partenaires sont titulaires exclusifs.
      </p>

      <p>
        Aucune reproduction, diffusion et/ou représentation, partielle ou
        intégrale, ne pourra en être faite sans l’accord préalable et écrit de
        Enriquez Ménager.
      </p>
      <p>
        De plus, Enriquez Ménager devra être informé préalablement à la mise en
        place d’un lien hypertexte en direction du site centcommentaire.fr et se
        réserve la possibilité de faire supprimer ce lien à tout moment.
      </p>
    </main>
  );
};

export default TermsAndCondition;
