import React from "react";
import IconComponent from "../IconComponent/IconComponent";

const PreviewPicture = ({ objectKey, state, setState, multiple }) => {
  const deletePictureFromGallery = (index) => {
    const copyState = { ...state };
    copyState.values[objectKey].splice(index, 1);
    setState(copyState);
  };

  return (
    <div>
      {!multiple ? (
        <>
          <div className="previw-picture-img-container">
            <img
              src={
                typeof state.values[objectKey] === "string"
                  ? state.values[objectKey]
                  : URL.createObjectURL(state.values[objectKey])
              }
              alt=""
            />
          </div>
          <button
            onClick={(e) => {
              const copyState = { ...state };
              copyState.values[objectKey] = "";
              setState(copyState);
            }}>
            Supprimer
          </button>
        </>
      ) : (
        <div className="preview-gallery-container">
          {state.values[objectKey] &&
            state.values[objectKey].length > 0 &&
            state.values[objectKey].map((picture, index) => {
              return (
                <div className="preview-gallery-single-img-container">
                  <IconComponent
                    icon="close-filled"
                    size={20}
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => deletePictureFromGallery(index)}
                  />
                  <img
                    src={
                      typeof picture === "string"
                        ? picture
                        : URL.createObjectURL(picture)
                    }
                    alt=""
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default PreviewPicture;
