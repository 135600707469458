import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import TableDeleteUpdateComponent from "../../Shared/components/TableDeleteUpdateComponent/TableDeleteUpdateComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";

const ContactListe = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    informationMessage,
    setModale,
  } = useGlobalStatesContext();

  const [dataList, setDataList] = useState([]);

  const fetchDataList = async () => {
    setIsLoading(true);
    const responseDataList = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "contact/backoffice",
      ""
    );
    if (responseDataList && responseDataList.statut === "OK") {
      setDataList(responseDataList.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDataList();
  }, []);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="backoffice-list-main">
      <h1>Liste des demandes d'information</h1>
      <div className="backoffice-list-container">
        <BackofficeGoBack collection={"demande d'information"} />
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />

        <ModaleComponent goBack={true} userSession={userSession} />

        <div className="table-container">
          {dataList && dataList.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prénom</th>

                  <th>Date</th>

                  <th>Modifier</th>
                  <th>Désactiver</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {dataList
                  .sort(
                    (a, b) =>
                      new Date(b.sending_date) - new Date(a.sending_date)
                  )
                  .map((item, index) => {
                    return (
                      <tr key={item["_id"]}>
                        <td>{item["last_name"] ? item["last_name"] : ""}</td>
                        <td>{item["first_name"] ? item["first_name"] : ""}</td>

                        <td>
                          {item["sending_date"]
                            ? new Date(
                                item["sending_date"]
                              ).toLocaleDateString()
                            : ""}
                        </td>

                        <TableDeleteUpdateComponent
                          setIsLoading={setIsLoading}
                          setInformationMessage={setInformationMessage}
                          setModale={setModale}
                          setDataList={setDataList}
                          dataList={dataList}
                          token={userSession.token}
                          index={index}
                          collection={"contact"}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <h2>Aucune demande d'information</h2>
          )}
        </div>
      </div>
    </main>
  );
};

export default ContactListe;
