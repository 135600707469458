import React, { useState } from "react";

import SignupForm from "../Auth/components/SignupForm";

const UserCreate = () => {
  const [authDataToSend, setAuthDataToSend] = useState({
    values: {
      role: "ADMIN",
    },
    touched: {},
    errors: {},
  });
  return (
    <main id="user-create-main">
      <h1>Ajouter un utilisateur</h1>
      <SignupForm
        authDataToSend={authDataToSend}
        setAuthDataToSend={setAuthDataToSend}
        fromList={true}
      />
    </main>
  );
};

export default UserCreate;
