import React, { useEffect, useState } from "react";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import {
  requestHandle,
  sendDataToServer,
} from "../../Shared/Utils/RequestFunctions";

import InputComponent from "../../Shared/components/Form/InputComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";

import LanguageComponent from "../../Shared/components/LanguageComponent/LanguageComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import { useLocation } from "react-router-dom";

const NavigationCreateUpdate = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    informationMessage,
    setInformationMessage,
    setModale,
    languageInput,
    setLanguageInput,
  } = useGlobalStatesContext();

  const isSingleID = useLocation().pathname.split("/").pop();

  const [dataToSend, setDataToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const validateNavigationForm = (state) => {
    const errorsObject = {};
    let isError = false;

    if (state.values) {
      if (!state.values.name_fr) {
        errorsObject.name_fr = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.title_fr) {
        errorsObject.title_fr = "Merci de renseigner ce champ";
        isError = true;
      }

      const copyNavigationData = { ...dataToSend };
      copyNavigationData.errors = errorsObject;
      setDataToSend(copyNavigationData);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendNavigationData = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    if (!validateNavigationForm(dataToSend)) {
      const copyNavigationData = { ...dataToSend };
      copyNavigationData.values["slug"] = dataToSend.values.name_fr
        .toLowerCase()
        .trim()
        .split(" ")
        .filter((elmt) => elmt !== "/")
        .join("-");

      await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        isSingleID !== "create" ? "PUT" : "POST",
        "navigation",
        dataToSend.values,
        setModale,
        {
          title: "Nouvelle catégorie ajoutée",
          content: "Une nouvelle catégorie à été ajouté à la liste",
          collection: "navigation",
          listeName: "navigation",
        }
      );
    }
    setIsLoading(false);
  };

  const fetchSingleData = async () => {
    if (isSingleID !== "create") {
      const responseFetchSingleData = await requestHandle(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "POST",
        "navigation",
        { single: isSingleID }
      );

      await Promise.all([responseFetchSingleData]).then((data) => {
        if (data[0]["statut"] === "OK") {
          setDataToSend((prevState) => {
            return {
              ...prevState,
              values: responseFetchSingleData.message,
            };
          });
        }
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleData();
  }, []);

  if (isLoading) return <LoadingComponent />;

  return (
    <main className="backoffice-form-main">
      <div className="backoffice-form-container">
        <BackofficeGoBack
          listeName={"des catégories de navigation"}
          collection={"navigation"}
        />
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />
        <ModaleComponent goBack={true}>
          <h1>Mettre à jour une catégorie de navigation</h1>

          <form onSubmit={(e) => sendNavigationData(e)}>
            <LanguageComponent nameClass={true} />
            <div className="input-container">
              <InputComponent
                inputType={"checkbox"}
                page={"backoffice-navigation"}
                label={""}
                objectKey={"robot_checkbox"}
                placeholder={""}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateNavigationForm}
              />

              <InputComponent
                inputType={"text"}
                required={languageInput === "FR" ? true : false}
                isError={
                  languageInput === "FR"
                    ? dataToSend.errors.name_fr
                    : dataToSend.errors.name_en
                }
                page={"backoffice-navigation"}
                label={`Nom ${
                  languageInput === "FR" ? "français" : "anglais"
                } de la catégorie de navigation`}
                objectKey={languageInput === "FR" ? "name_fr" : "name_en"}
                placeholder={`le nom ${
                  languageInput === "FR" ? "français" : "anglais"
                }  de la catégorie`}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateNavigationForm}
              />

              <InputComponent
                inputType={"text"}
                required={languageInput === "FR" ? true : false}
                isError={
                  languageInput === "FR"
                    ? dataToSend.errors.title_fr
                    : dataToSend.errors.title_en
                }
                page={"backoffice-navigation"}
                label={`Titre ${
                  languageInput === "FR" ? "français" : "anglais"
                } de la catégorie de navigation`}
                objectKey={languageInput === "FR" ? "title_fr" : "title_en"}
                placeholder={`le titre ${
                  languageInput === "FR" ? "français" : "anglais"
                }  de la catégorie`}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateNavigationForm}
              />

              <InputComponent
                inputType={"text"}
                required={languageInput === "FR" ? true : false}
                isError={dataToSend.errors.content_fr}
                page={"backoffice-navigation"}
                label={`Texte pour le référencement`}
                objectKey={"content_fr"}
                placeholder={`texte pour le référencement`}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateNavigationForm}
              />

              <InputComponent
                inputType={"picture"}
                page={"backoffice-navigation"}
                label={"Photo de présentation de la page"}
                objectKey={"category_picture"}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateNavigationForm}
              />

              <InputComponent
                inputType={"picture"}
                page={"backoffice-navigation"}
                label={"Pictogramme de la page"}
                objectKey={"category_picto"}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateNavigationForm}
              />
            </div>
            <button type="submit">Envoyer</button>
          </form>
        </ModaleComponent>
      </div>
    </main>
  );
};

export default NavigationCreateUpdate;
