export const onChangeFormInput = (
  key,
  value,
  state,
  setState,
  validateFunction
) => {
  const copyFormObject = { ...state };
  copyFormObject.touched[key] = true;
  copyFormObject.values[key] = value;
  setState(copyFormObject);
  validateFunction(copyFormObject);
};
