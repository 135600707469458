import React from "react";

import { motion, AnimatePresence } from "framer-motion";
import IconComponent from "../IconComponent/IconComponent";

const InformationMessageComponent = ({
  informationMessage,
  setInformationMessage,
}) => {
  const variants = {
    dislpay: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <ul className="information-message-ul">
      <AnimatePresence initial={false}>
        <motion.div
          animate={informationMessage ? "dislpay" : "hidden"}
          variants={variants}>
          <div
            className="information-message-container"
            style={{
              backgroundColor:
                informationMessage &&
                informationMessage.messageType &&
                informationMessage.messageType === "success"
                  ? "#198754"
                  : "#dd5735",
            }}>
            <div className="information-message-close-container">
              <IconComponent
                icon="close-filled"
                size={20}
                color="#fff"
                style={{ cursor: "pointer" }}
                onClick={() => setInformationMessage("")}
              />
            </div>
            {informationMessage && informationMessage.title && (
              <p className="information-message-title">
                {informationMessage.title}
              </p>
            )}
            {informationMessage && informationMessage.content && (
              <p className="information-message-content">
                {informationMessage.content}
              </p>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </ul>
  );
};

export default InformationMessageComponent;
