import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import { useNavigate } from "react-router-dom";
import IconComponent from "../IconComponent/IconComponent";

import { useGlobalStatesContext } from "../../Context/GlobalStates";

import { requestHandle } from "../../Utils/RequestFunctions";
import BackofficeGoBack from "../BackofficeGoBack/BackofficeGoBack";

const ModaleComponent = ({ goBack, children, userSession }) => {
  const { modale, setModale, setIsLoading, setInformationMessage } =
    useGlobalStatesContext();

  const navigate = useNavigate();

  const variants = {
    active: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };

  const deleteDocument = async () => {
    if (modale.documentToDelete) {
      const copyDataList = [...modale["list"]];
      copyDataList.splice(modale.index, 1);
      modale.setDataList(copyDataList);

      await requestHandle(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "DELETE",
        modale.collection,
        { type: "document", id: modale.documentToDelete["_id"] }
      );
    }
  };
  return (
    <>
      <AnimatePresence initial={false}>
        <motion.div
          id="modale"
          animate={modale ? "active" : "hidden"}
          variants={variants}>
          <div className="close-container">
            <IconComponent
              icon="close-filled"
              size={20}
              color="var(--red)"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (modale["origine"] === "contact") {
                  navigate("/");
                }
                setModale(false);
              }}
            />
          </div>
          {modale.title ? <h3>{modale.title}</h3> : null}

          {modale.content ? <p>{modale.content}</p> : null}

          {goBack ? (
            <BackofficeGoBack
              listeName={modale.listeName}
              collection={modale.collection}
            />
          ) : null}

          {modale.documentToDelete ? (
            <div className="modal-delete-button-container">
              <button
                onClick={() => {
                  deleteDocument();
                  setModale(false);
                }}>
                Supprimer
              </button>
              <button
                onClick={() => {
                  setModale(false);
                }}>
                Annuler
              </button>
            </div>
          ) : null}
        </motion.div>
      </AnimatePresence>
      <div className="modale-wrapper" style={{ opacity: modale ? 0.3 : 1 }}>
        {children}
      </div>
    </>
  );
};

export default ModaleComponent;
