import React, { useState, useEffect, useRef } from "react";

import { sanitize } from "dompurify";
import { useLocation, useNavigate } from "react-router-dom";
import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";
import LanguageSwapOpacityWrapper from "../../Shared/components/languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import InputComponent from "../../Shared/components/Form/InputComponent";

const ClientPage = () => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    languageWebSite,
    innerWidthState,
  } = useGlobalStatesContext();

  const navigate = useNavigate();

  const location = useLocation();
  const lastHash = useRef("");

  const splittedPathname = location.pathname.split("/");

  const [categoryData, setCategoryData] = useState([]);
  const [modaleInformation, setModaleInformation] = useState({});

  const fetchPageData = async () => {
    setIsLoading(true);

    const responseHomeData = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "POST",
      "client/data",
      { slug: splittedPathname[2] }
    );
    if (responseHomeData && responseHomeData.statut === "OK") {
      setCategoryData(responseHomeData.message);
    } else {
      // navigate("/");
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchPageData();
  }, [location.pathname]);

  useEffect(() => {
    if (!isLoading) {
      if (location.hash) {
        lastHash.current = location.hash.slice(1);
      }

      setTimeout(() => {
        if (lastHash.current && document.getElementById(lastHash.current)) {
          document
            .getElementById(lastHash.current)
            .scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }, 400);
    }
  }, [location, isLoading]);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main
      id="client-main"
      className={`container-${
        categoryData && categoryData["slug"] ? categoryData["slug"] : ""
      }`}>
      <div className="box"></div>

      {categoryData && (
        <>
          <LanguageSwapOpacityWrapper
            backgroundImage={categoryData["category_picture"]}
            nameClass={"category-title-image"}>
            <div className="category-title-container">
              <h1>
                {languageWebSite === "FR"
                  ? categoryData["title_fr"]
                  : categoryData["title_en"]
                  ? categoryData["title_en"]
                  : categoryData["title_fr"]}
              </h1>
              {categoryData["content_fr"] && (
                <p className="referencement-text">
                  {categoryData["content_fr"]}
                </p>
              )}
            </div>
          </LanguageSwapOpacityWrapper>

          {categoryData["sections"] &&
            categoryData["sections"].length > 0 &&
            categoryData["sections"].map((section, indexSection) => {
              return (
                <section key={section["_id"]} id={section["slug"]}>
                  <div className="section-details-container">
                    {section["section_picture"] ? (
                      <div className="section-picture-container">
                        <img src={section["section_picture"]} alt="" />
                      </div>
                    ) : null}

                    {section["title_fr"] && (
                      <LanguageSwapOpacityWrapper>
                        <h2>
                          {languageWebSite === "FR"
                            ? section["title_fr"]
                            : section["title_en"]
                            ? section["title_en"]
                            : section["title_fr"]}
                        </h2>
                      </LanguageSwapOpacityWrapper>
                    )}

                    {section["content_fr"] && (
                      <LanguageSwapOpacityWrapper>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              languageWebSite === "FR"
                                ? sanitize(section["content_fr"])
                                : sanitize(section["content_en"])
                                ? sanitize(section["content_en"])
                                : sanitize(section["content_fr"]),
                          }}></div>
                      </LanguageSwapOpacityWrapper>
                    )}
                  </div>
                  <div className="service-article-container">
                    {section["services"] &&
                      section["services"].length > 0 &&
                      section["services"].map((service, index) => {
                        if (service["statut"])
                          return (
                            <article
                              key={service["_id"]}
                              id={service["slug"]}
                              className={`service-article${
                                innerWidthState > 768 &&
                                section["services"].filter(
                                  (elmt) => elmt["statut"] === true
                                ).length === 1
                                  ? " one-article"
                                  : ""
                              }`}>
                              <LanguageSwapOpacityWrapper
                                nameClass={"service-title-container"}>
                                <h3>
                                  {languageWebSite === "FR"
                                    ? service["title_fr"]
                                    : service["title_en"]
                                    ? service["title_en"]
                                    : service["title_fr"]}
                                </h3>
                              </LanguageSwapOpacityWrapper>

                              {service["service_picture"] ? (
                                <div className="service-article-picture-container">
                                  <img
                                    src={service["service_picture"]}
                                    alt=""
                                  />
                                </div>
                              ) : null}

                              <LanguageSwapOpacityWrapper
                                nameClass={"service-article-content"}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      languageWebSite === "FR"
                                        ? sanitize(service["content_fr"])
                                        : sanitize(service["content_en"])
                                        ? sanitize(service["content_en"])
                                        : sanitize(service["content_fr"]),
                                  }}></div>
                                {service["has_button"] && (
                                  <button
                                    className="card-button"
                                    onClick={() => {
                                      navigate("/contact", {
                                        state: {
                                          service:
                                            categoryData[
                                              "name_fr"
                                            ].toLowerCase(),
                                          subject: "information",
                                          message:
                                            languageWebSite === "FR"
                                              ? `Je voudrais en savoir plus à propos du service ${service["title_fr"]}`
                                              : `I would like to learn more about ${
                                                  service["title_en"]
                                                    ? service["title_en"]
                                                    : service["title_fr"]
                                                }`,
                                        },
                                      });
                                    }}>
                                    {languageWebSite === "FR"
                                      ? "Demande d'informations"
                                      : "Information request"}
                                  </button>
                                )}
                              </LanguageSwapOpacityWrapper>
                            </article>
                          );
                      })}
                  </div>
                  {categoryData["category_picto"] &&
                    indexSection < categoryData["sections"].length - 1 && (
                      <div className="picto-border">
                        <img src={categoryData["category_picto"]} alt="" />
                      </div>
                    )}
                </section>
              );
            })}
        </>
      )}
    </main>
  );
};

export default ClientPage;
