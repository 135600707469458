import React from "react";

import { useNavigate } from "react-router-dom";

import { statutUpdate } from "../../Utils/RequestFunctions";

const TableDeleteUpdateComponent = ({
  setIsLoading,
  setInformationMessage,
  collection,
  token,
  index,
  dataList,
  setDataList,
  setModale,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <td className="td-list-button">
        <button
          className="list-button update-button"
          onClick={() =>
            navigate(
              `/backoffice/${collection}/update/${dataList[index]["_id"]}`
            )
          }>
          Modifier
        </button>
      </td>
      <td className="td-list-button">
        <button
          className="list-button deactivate-button"
          style={
            dataList[index]["statut"]
              ? { backgroundColor: "var(--red)" }
              : { backgroundColor: "green" }
          }
          onClick={() => {
            statutUpdate(
              setIsLoading,
              setInformationMessage,
              collection,
              token,
              index,
              dataList,
              setDataList
            );
          }}>
          {dataList[index]["statut"] ? "Désactiver" : "Activer"}
        </button>
      </td>
      <td className="td-list-button">
        <button
          className="list-button delete-button"
          onClick={async () => {
            setModale({
              list: dataList,
              documentToDelete: dataList[index],
              index: index,
              setDataList: setDataList,
              collection: collection,
              title: "Êtes vous sur de vouloir supprimer ce document ?",
            });
          }}>
          Supprimer
        </button>
      </td>
    </>
  );
};

export default TableDeleteUpdateComponent;
