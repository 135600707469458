import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import axios from "axios";
import { motion } from "framer-motion";

import InputComponent from "../../Shared/components/Form/InputComponent";

import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import LanguageSwapOpacityWrapper from "../../Shared/components/languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";
import { onChangeFormInput } from "../../Shared/Utils/EventFunctions";
import {
  requestHandle,
  sendDataToServer,
} from "../../Shared/Utils/RequestFunctions";

const Contact = () => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    informationMessage,
    languageWebSite,
    setModale,
  } = useGlobalStatesContext();

  const { state } = useLocation();

  const [category, setCategory] = useState([]);

  const [contactData, setContactData] = useState({
    values: state
      ? {
          message: state.message,
          subject: state.subject,
          service: state.service,
        }
      : {},
    touched: {},
    errors: {},
  });

  const [autoCompleteData, setAutoCompleteData] = useState([]);

  const fetchCategorySubject = async () => {
    setIsLoading(true);
    const responseCategorySubject = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "GET",
      "client/contact",
      ""
    );
    if (responseCategorySubject && responseCategorySubject.statut === "OK") {
      setCategory(responseCategorySubject.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCategorySubject();
  }, []);

  const fetchAutoCompleteData = async () => {
    if (contactData.values.adress) {
      try {
        const url_server = `https://api-adresse.data.gouv.fr/search/?q=${contactData.values.adress
          .split(" ")
          .join("+")}&limit=5`;

        const response = await axios.get(url_server);
        setAutoCompleteData(response.data.features);
      } catch (error) {
        // setInformationMessage({
        //   title: "Erreur d'auto-completion",
        //   content: "L'auto complétion n'est pas disponible",
        // });
      }
    }
  };

  useEffect(() => {
    fetchAutoCompleteData();
  }, [contactData.values.adress]);

  const validateContact = (state) => {
    const errorsObject = {};
    let isError = false;

    if (state.values) {
      if (state.values.robot_checkbox) {
        isError = true;
        setInformationMessage({
          title: "Unauthorized",
          content: "Unauthorized action",
        });
      }
      if (!state.values.last_name) {
        errorsObject.last_name = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.phone_number) {
        errorsObject.phone_number = "Merci de renseigner ce champ";
        isError = true;
      }

      if (
        state.values.email &&
        !state.values.email
          .toLowerCase()
          .match(
            /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
          )
      ) {
        errorsObject.email = "Merci de renseigner un email valide";
        isError = true;
      }

      if (!state.values.adress) {
        errorsObject.adress = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.post_code) {
        errorsObject.post_code = "Merci de renseigner ce champ";
        isError = true;
      }
      if (!state.values.message) {
        errorsObject.message = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.city) {
        errorsObject.city = "Merci de renseigner ce champ";
        isError = true;
      }

      const copyContactData = { ...contactData };
      copyContactData.errors = errorsObject;
      setContactData(copyContactData);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendContactData = async (e) => {
    e.preventDefault();

    if (!validateContact(contactData)) {
      contactData.values["sending_date"] = Date.now();
      const responseContact = await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        "",
        "POST",
        "contact",
        contactData.values,
        setModale,
        {
          title: "Votre message à bien été envoyé",
          content: "Nous vous recontacterons dans les plus brefs délais",
          origine: "contact",
        }
      );
    } else {
      const keysObjectToSend = Object.keys(contactData.errors);

      let newTouchedObject = {};

      keysObjectToSend.forEach((key) => {
        newTouchedObject = { ...newTouchedObject, [`${key}`]: true };
      });

      const copyContactData = { ...contactData };
      copyContactData.touched = newTouchedObject;
      setContactData(copyContactData);

      setInformationMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
  };

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="contact-main">
      <InformationMessageComponent
        informationMessage={informationMessage}
        setInformationMessage={setInformationMessage}
      />
      <ModaleComponent />

      <LanguageSwapOpacityWrapper>
        <h1>{languageWebSite === "FR" ? "Nous contacter" : "Contact us"}</h1>
      </LanguageSwapOpacityWrapper>

      <LanguageSwapOpacityWrapper>
        <p>
          {languageWebSite === "FR"
            ? "Les demandes de devis sont gratuites. Nous nous engageons à vous répondre dans les plus brefs délais."
            : "Quotations requests are free of charge. We are committed to responding to you as soon as possible"}
        </p>
      </LanguageSwapOpacityWrapper>

      <form onSubmit={(e) => sendContactData(e)}>
        <fieldset>
          <InputComponent
            inputType={"text"}
            isError={
              contactData.errors.last_name && contactData.touched.last_name
            }
            page={"contact"}
            objectKey={"last_name"}
            label={languageWebSite === "FR" ? "Nom" : "Lastname"}
            required={true}
            placeholder={languageWebSite === "FR" ? "votre nom" : ""}
            state={contactData}
            setState={setContactData}
            validateFunction={validateContact}
          />

          <InputComponent
            inputType={"text"}
            isError={
              contactData.errors.first_name && contactData.touched.first_name
            }
            page={"contact"}
            objectKey={"first_name"}
            label={languageWebSite === "FR" ? "Prénom" : "Firstname"}
            placeholder={languageWebSite === "FR" ? "votre prénom" : ""}
            state={contactData}
            setState={setContactData}
            validateFunction={validateContact}
          />
        </fieldset>

        <fieldset>
          <InputComponent
            inputType={"text"}
            isError={contactData.errors.email && contactData.touched.email}
            page={"contact"}
            objectKey={"email"}
            label={"Email"}
            placeholder={languageWebSite === "FR" ? "votre email" : ""}
            state={contactData}
            setState={setContactData}
            validateFunction={validateContact}
          />

          <InputComponent
            inputType={"text"}
            isError={
              contactData.errors.phone_number &&
              contactData.touched.phone_number
            }
            page={"contact"}
            objectKey={"phone_number"}
            label={languageWebSite === "FR" ? "Téléphone" : "Phone number"}
            required={true}
            placeholder={
              languageWebSite === "FR" ? "un numéro de téléphone" : ""
            }
            state={contactData}
            setState={setContactData}
            validateFunction={validateContact}
          />
        </fieldset>

        <InputComponent
          inputType={"text"}
          isError={contactData.errors.adress && contactData.touched.adress}
          page={"contact"}
          objectKey={"adress"}
          label={languageWebSite === "FR" ? "Adresse" : "Adress"}
          required={true}
          placeholder={languageWebSite === "FR" ? "une adresse" : ""}
          state={contactData}
          setState={setContactData}
          validateFunction={validateContact}
        />

        {autoCompleteData &&
          autoCompleteData.length > 0 &&
          contactData.values.adress &&
          !contactData.values.city &&
          !contactData.values.post_code && (
            <div className="autocomplete-adress-container">
              <ul>
                {autoCompleteData.map((data, index) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => {
                        const copyContactData = { ...contactData };

                        copyContactData.values.adress = data.properties.name;
                        copyContactData.values.post_code =
                          data.properties.postcode;
                        copyContactData.values.city = data.properties.city;

                        copyContactData.errors.adress = false;
                        copyContactData.errors.post_code = false;
                        copyContactData.errors.city = false;

                        setContactData(copyContactData);

                        setAutoCompleteData([]);
                      }}>
                      <motion.p
                        whileHover={{
                          scale: 1.02,
                          fontWeight: "bold",
                        }}>
                        {data.properties.label}
                      </motion.p>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        <fieldset>
          <InputComponent
            inputType={"text"}
            isError={contactData.errors.city && contactData.touched.city}
            page={"contact"}
            objectKey={"city"}
            label={languageWebSite === "FR" ? "Ville" : "City"}
            required={true}
            placeholder={languageWebSite === "FR" ? "une ville" : ""}
            state={contactData}
            setState={setContactData}
            validateFunction={validateContact}
          />

          <InputComponent
            inputType={"text"}
            isError={
              contactData.errors.post_code && contactData.touched.post_code
            }
            page={"contact"}
            objectKey={"post_code"}
            label={languageWebSite === "FR" ? "Code postal" : "Post code"}
            required={true}
            placeholder={languageWebSite === "FR" ? "un code postal" : ""}
            state={contactData}
            setState={setContactData}
            validateFunction={validateContact}
          />
        </fieldset>

        <fieldset>
          <div className="input-group">
            <select
              name="contact-type"
              id="contact-type"
              value={
                contactData.values["subject"]
                  ? contactData.values["subject"]
                  : ""
              }
              onChange={(e) => {
                onChangeFormInput(
                  "subject",
                  e.target.value,
                  contactData,
                  setContactData,
                  validateContact
                );
              }}>
              <option value="">
                {languageWebSite === "FR" ? "Sujet" : "Subject"}
              </option>

              <option value="depannage">
                {languageWebSite === "FR" ? "Dépannage" : "Repair"}
              </option>
              <option value="devis">
                {languageWebSite === "FR" ? "Devis" : "Quote"}
              </option>
              <option value="entretien">
                {languageWebSite === "FR" ? "Entretien" : "Maintenance"}
              </option>

              <option value="information">
                {languageWebSite === "FR" ? "Informations" : "Know more"}
              </option>
            </select>
          </div>

          <div className="input-group">
            <select
              name="service"
              id="service"
              value={
                contactData.values["service"]
                  ? contactData.values["service"]
                  : ""
              }
              onChange={(e) => {
                onChangeFormInput(
                  "service",
                  e.target.value,
                  contactData,
                  setContactData,
                  validateContact
                );
              }}>
              <option value="">
                {languageWebSite === "FR"
                  ? "Choisissez une catégorie"
                  : "Choose a category"}
              </option>
              {category &&
                category.length > 0 &&
                category
                  .filter((elmt) => elmt["name_fr"] !== "Accueil")
                  .map((option) => {
                    return (
                      <option
                        key={option["_id"]}
                        value={option["name_fr"].toLowerCase()}>
                        {languageWebSite === "FR"
                          ? option.name_fr
                          : option.name_en
                          ? option.name_en
                          : option.name_fr}
                      </option>
                    );
                  })}
            </select>
          </div>
        </fieldset>
        <InputComponent
          inputType={"textarea"}
          isError={contactData.errors.message && contactData.touched.message}
          page={"contact"}
          objectKey={"message"}
          label={languageWebSite === "FR" ? "Votre message" : "Your message"}
          required={true}
          placeholder={"un message"}
          state={contactData}
          setState={setContactData}
          validateFunction={validateContact}
        />

        <LanguageSwapOpacityWrapper>
          <button type="submit">
            {languageWebSite === "FR" ? "Envoyer" : "Send"}
          </button>
        </LanguageSwapOpacityWrapper>
      </form>
    </main>
  );
};

export default Contact;
