import { Routes, Route, Navigate, Link, useNavigate } from "react-router-dom";

import Auth from "../Backoffice/Auth/Auth";
import BackofficeHome from "../Backoffice/BackofficeHome/BackofficeHome";

import NavigationListe from "../Backoffice/Navigation/NavigationListe";
import NavigationCreateUpdate from "../Backoffice/Navigation/NavigationCreateUpdate";

import SectionListe from "../Backoffice/Section/SectionListe";
import SectionCreateUpdate from "../Backoffice/Section/SectionCreateUpdate";

import ServiceListe from "../Backoffice/Service/ServiceListe";
import ServiceCreateUpdate from "../Backoffice/Service/ServiceCreateUpdate";

import RealisationListe from "../Backoffice/Realisations/RealisationListe";
import RealisationCreateUpdate from "../Backoffice/Realisations/RealisationsCreateUpdate";

import ContactListe from "../Backoffice/Contact/ContactListe";
import ContactDetailsUpdate from "../Backoffice/Contact/ContactDetailsUpdate";

import UsersList from "../Backoffice/Users/UsersListe";
import UserCreate from "../Backoffice/Users/UserCreate";

const BackofficeRoutes = ({ userSession, setUser }) => {
  return (
    <>
      {userSession && (
        <div className="backoffice-header">
          <div className="backoffice-header-button-container">
            <Link
              to="/"
              onClick={() => {
                setUser(null);
              }}>
              Déconnexion
            </Link>
            <Link to={"/backoffice/home"}>Accueil gestion</Link>
            <Link target="_blank" to={"/"}>
              Voir le site
            </Link>
          </div>
        </div>
      )}
      <Routes>
        {!userSession ? (
          <>
            <Route
              path="/admin/login/5830"
              element={<Auth setUser={setUser} />}
            />
            {/* <Route
              path="*"
              element={<Navigate to="/" replace />}
              // element={<Navigate to="/backoffice/admin/login/5830" replace />}
            /> */}
          </>
        ) : (
          <>
            <Route
              path="/home"
              element={
                <BackofficeHome setUser={setUser} userSession={userSession} />
              }
            />

            {/* ************************** NAVIGATION ************************** */}
            <Route
              path="/navigation/liste"
              element={<NavigationListe userSession={userSession} />}
            />

            {/* <Route
          path="/navigation/create"
          element={<NavigationCreateUpdate userSession={userSession} />}
        /> */}

            <Route
              path="/navigation/update/:navigation_ID"
              element={<NavigationCreateUpdate userSession={userSession} />}
            />

            {/* ************************** SECTIONS ************************** */}
            <Route
              path="/section/liste"
              element={<SectionListe userSession={userSession} />}
            />

            <Route
              path="/section/create"
              element={<SectionCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/section/update/:section_ID"
              element={<SectionCreateUpdate userSession={userSession} />}
            />

            {/* ************************** SERVICE ************************** */}

            <Route
              path="/service/liste"
              element={<ServiceListe userSession={userSession} />}
            />

            <Route
              path="/service/create"
              element={<ServiceCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/service/update/:service_ID"
              element={<ServiceCreateUpdate userSession={userSession} />}
            />

            {/* ************************** REALISATION ************************** */}

            <Route
              path="/realisation/liste"
              element={<RealisationListe userSession={userSession} />}
            />

            <Route
              path="/realisation/create"
              element={<RealisationCreateUpdate userSession={userSession} />}
            />

            <Route
              path="/realisation/update/:realisation_ID"
              element={<RealisationCreateUpdate userSession={userSession} />}
            />

            {/* ************************** CONTACT ************************** */}

            <Route
              path="/contact/liste"
              element={<ContactListe userSession={userSession} />}
            />

            <Route
              path="/contact/update/:realisation_ID"
              element={<ContactDetailsUpdate userSession={userSession} />}
            />

            {/* ************************** USERS ************************** */}

            <Route
              path="/users/liste"
              element={<UsersList userSession={userSession} />}
            />

            <Route
              path="/users/create"
              element={<UserCreate userSession={userSession} />}
            />

            {/* ************************** REDIRECTION ************************** */}

            {/* <Route
              path="*"
              element={<Navigate to="/backoffice/home" replace />}
            /> */}
          </>
        )}
      </Routes>
    </>
  );
};

export default BackofficeRoutes;
