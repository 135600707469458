import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import {
  requestHandle,
  sendDataToServer,
} from "../../Shared/Utils/RequestFunctions";

import InputComponent from "../../Shared/components/Form/InputComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";

import LanguageComponent from "../../Shared/components/LanguageComponent/LanguageComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import CkEditorComponent from "../../Shared/components/CkEditor/CkEditorComponent";

const RealisationCreateUpdate = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    informationMessage,
    setInformationMessage,
    setModale,
    languageInput,
  } = useGlobalStatesContext();

  const { realisation_ID } = useParams();
  const isSingleID = useLocation().pathname.split("/").pop();

  const [dataToSend, setDataToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const [categoryToSelect, setCategoryToSelect] = useState([]);

  const validateForm = (state) => {
    const errorsObject = {};
    let isError = false;

    if (state.values) {
      if (!state.values.title_fr) {
        errorsObject.title_fr = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.content_fr) {
        errorsObject.content_fr = "Merci de renseigner ce champ";
        isError = true;
      }
      const copyNavigationData = { ...dataToSend };
      copyNavigationData.errors = errorsObject;
      setDataToSend(copyNavigationData);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendRealisationData = async (e) => {
    e.preventDefault();

    if (!validateForm(dataToSend)) {
      await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        isSingleID !== "create" ? "PUT" : "POST",
        "realisation",
        dataToSend.values,
        setModale,
        {
          title: "Nouvel realisation ajoutée",
          content: "Un nouvel realisation a été ajoutée à la liste",
          collection: "realisation",
          listeName: "realisations",
        }
      );
    } else {
      setInformationMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
  };

  const fetchSingleData = async () => {
    if (isSingleID !== "create") {
      setIsLoading(true);
      const responseFetchSingleData = await requestHandle(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "POST",
        "realisation",
        { single: isSingleID }
      );
      if (responseFetchSingleData && responseFetchSingleData.statut === "OK") {
        setDataToSend((prevState) => {
          return {
            ...prevState,
            values: {
              ...responseFetchSingleData.message,
              galleryPictures:
                responseFetchSingleData.message["gallery"].split(","),
            },
          };
        });
      }
      setIsLoading(false);
    }
  };

  const fetchCategoryToSelect = async () => {
    setIsLoading(true);
    const responseCategory = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "navigation",
      ""
    );

    if (responseCategory && responseCategory.statut === "OK") {
      setCategoryToSelect(responseCategory.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleData();
    fetchCategoryToSelect();
  }, []);

  if (isLoading) return <LoadingComponent />;

  return (
    <main className="backoffice-form-main">
      <h1>
        {isSingleID === "create"
          ? "Créer un nouvelle realisation"
          : "Mettre à jour une realisation"}
      </h1>
      <div className="backoffice-form-container">
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />

        <ModaleComponent goBack={true}>
          <LanguageComponent nameClass={true} />
          <form onSubmit={(e) => sendRealisationData(e)}>
            <div className="input-container">
              <InputComponent
                inputType={"checkbox"}
                page={"backoffice-realisation"}
                label={""}
                objectKey={"robot_checkbox"}
                placeholder={""}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateForm}
              />

              <InputComponent
                inputType={"select"}
                required={languageInput === "FR" ? true : false}
                isError={
                  dataToSend.errors["category"]
                    ? dataToSend.errors["category"]
                    : false
                }
                page={"backoffice-sub-section"}
                label={`Nom de la categorie associée`}
                objectKey={"category"}
                placeholder={`le nom de la catégorie associée`}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateForm}
                arrayOptions={
                  categoryToSelect
                    ? categoryToSelect.filter(
                        (elmt) =>
                          elmt.statut === true && elmt.name_fr !== "Accueil"
                      )
                    : []
                }
              />

              <>
                <InputComponent
                  inputType={"text"}
                  required={languageInput === "FR" ? true : false}
                  isError={
                    languageInput === "FR" &&
                    dataToSend.errors.title_fr &&
                    dataToSend.touched.title_fr
                      ? dataToSend.errors.title_fr
                      : dataToSend.errors.title_en &&
                        dataToSend.touched.title_en &&
                        dataToSend.errors.title_en
                  }
                  page={"backoffice-realisation"}
                  objectKey={languageInput === "FR" ? "title_fr" : "title_en"}
                  label={`Titre ${
                    languageInput === "FR" ? "français" : "anglais"
                  } de la réalisation`}
                  placeholder={`le titre ${
                    languageInput === "FR" ? "français" : "anglais"
                  }  de la réalisation`}
                  state={dataToSend}
                  setState={setDataToSend}
                  validateFunction={validateForm}
                />

                <CkEditorComponent
                  label={"Le texte de la réalisation"}
                  required={languageInput === "FR" ? true : false}
                  page={"backoffice-service"}
                  state={dataToSend}
                  setState={setDataToSend}
                  objectKey={
                    languageInput === "FR" ? "content_fr" : "content_en"
                  }
                />

                <InputComponent
                  inputType={"picture"}
                  page={"backoffice-realisation"}
                  label={"Photo de présentation de la realisation"}
                  objectKey={"realisation_picture"}
                  state={dataToSend}
                  setState={setDataToSend}
                  validateFunction={validateForm}
                />

                <InputComponent
                  inputType={"picture"}
                  page={"backoffice-realisation"}
                  label={"Gallerie Photo de la réalisation"}
                  objectKey={"galleryPictures"}
                  state={dataToSend}
                  setState={setDataToSend}
                  validateFunction={validateForm}
                  multipleOption={true}
                />
              </>
            </div>

            <button type="submit">Envoyer</button>
          </form>
        </ModaleComponent>
      </div>
    </main>
  );
};

export default RealisationCreateUpdate;
