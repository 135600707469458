import React from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

const BackofficeHome = ({ userSession }) => {
  return (
    <main id="backoffice-home-main">
      <h1>Gestion</h1>
      <div className="backoffice-home-ul-container">
        <ul className="collection-manager-ul">
          <li>
            <h2>Navigation</h2>
            <ul>
              {/* <li>
                <Link to={"/backoffice/navigation/create"}>
                  Créer une nouvelle catégorie de navigation
                </Link>
              </li> */}

              <li>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/navigation/liste"}>
                    Liste des catégories de navigation
                  </Link>
                </motion.div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="collection-manager-ul">
          <li>
            <h2>Ensemble de services</h2>
            <ul>
              <li whileHover={{ scale: 1.1 }}>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/section/create"}>
                    Créer un nouvel ensembles de services
                  </Link>
                </motion.div>
              </li>

              <li whileHover={{ scale: 1.1 }}>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/section/liste"}>
                    Liste des ensembles de services
                  </Link>
                </motion.div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="collection-manager-ul">
          <li>
            <h2>Services</h2>
            <ul>
              <li whileHover={{ scale: 1.1 }}>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/service/create"}>
                    Créer un nouveau service
                  </Link>
                </motion.div>
              </li>

              <li whileHover={{ scale: 1.1 }}>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/service/liste"}>
                    Liste des services
                  </Link>
                </motion.div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="collection-manager-ul">
          <li>
            <h2>Réalisations</h2>
            <ul>
              <li whileHover={{ scale: 1.1 }}>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/realisation/create"}>
                    Créer une nouvelle réalisation
                  </Link>
                </motion.div>
              </li>

              <li whileHover={{ scale: 1.1 }}>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/realisation/liste"}>
                    Liste des réalisations
                  </Link>
                </motion.div>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="collection-manager-ul">
          <li>
            <h2>Demande d'information</h2>
            <ul>
              <li>
                <motion.div
                  whileHover={{
                    scale: 1.1,
                  }}>
                  <Link to={"/backoffice/contact/liste"}>
                    Liste des demandes d'information
                  </Link>
                </motion.div>
              </li>
            </ul>
          </li>
        </ul>

        {userSession.role === "SUPER_ADMIN" && (
          <ul className="collection-manager-ul">
            <li>
              <h2>Utilisateurs</h2>
              <ul>
                <li>
                  <motion.div
                    whileHover={{
                      scale: 1.1,
                    }}>
                    <Link to={"/backoffice/users/liste"}>Liste des users</Link>
                  </motion.div>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </div>
    </main>
  );
};

export default BackofficeHome;
