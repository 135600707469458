import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import { useGlobalStatesContext } from "../../Context/GlobalStates";

const LanguageSwapOpacityWrapper = ({
  children,
  nameClass,
  backgroundImage,
}) => {
  const { refreshLanguageOpacity } = useGlobalStatesContext();
  const textToFadeVariants = {
    fade: {
      opacity: refreshLanguageOpacity,
    },
  };
  return (
    <AnimatePresence>
      <motion.div
        animate={"fade"}
        variants={textToFadeVariants}
        transition={{ duration: 0.2 }}
        className={nameClass ? nameClass : ""}
        style={
          backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}
        }>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default LanguageSwapOpacityWrapper;
