import React, { useState } from "react";

// IMPORT CONTEXT
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

// IMPORT COMPONENTS
import SignupForm from "./components/SignupForm";
import LoginForm from "./components/LoginForm";

// IMPORT SHARED COMPONENTS
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";

const Auth = ({ setUser }) => {
  const { informationMessage, setInformationMessage } =
    useGlobalStatesContext();

  const [authDataToSend, setAuthDataToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  return (
    <main id="auth-container">
      {informationMessage && (
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />
      )}
      <div className="auth-forms-container">
        <h1>Connectez-vous</h1>

        <LoginForm
          authDataToSend={authDataToSend}
          setAuthDataToSend={setAuthDataToSend}
          setUser={setUser}
        />
      </div>
    </main>
  );
};

export default Auth;
