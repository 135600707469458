import React from "react";

import { onChangeFormInput } from "../../Utils/EventFunctions";
import PreviewPicture from "../PreviewPicture/PreviewPicture";
import LanguageComponent from "../LanguageComponent/LanguageComponent";
import LanguageSwapOpacityWrapper from "../languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";

const InputComponent = ({
  inputType,
  isError,
  page,
  label,
  objectKey,
  placeholder,
  state,
  setState,
  validateFunction,
  arrayOptions,
  disableCondition,
  language,
  multipleOption,
  required,
}) => {
  return (
    <div
      className={`input-group ${
        objectKey === "robot_checkbox" ? "input-robot" : ""
      }`}>
      {label && (
        <LanguageSwapOpacityWrapper nameClass={"label-opacity-wrapper"}>
          <label
            style={
              isError ? { color: "var(--red)" } : { color: "var(--black)" }
            }
            htmlFor={`form-input-${page}-${objectKey}`}>
            {label}{" "}
            {required ? <span className="required-span"> &#8239;*</span> : ""}
          </label>
        </LanguageSwapOpacityWrapper>
      )}
      {inputType === "text" ||
      inputType === "email" ||
      inputType === "password" ||
      inputType === "checkbox" ? (
        <>
          <input
            disabled={disableCondition ? true : false}
            type={inputType}
            style={isError ? { border: "solid var(--red) 2px" } : {}}
            id={`form-input-${page}-${objectKey}`}
            name={`form-input-${page}-${objectKey}`}
            className={objectKey === "robot_checkbox" ? "robot-checkbox" : ""}
            placeholder={`Merci de renseigner ${placeholder}`}
            value={
              state.values[objectKey]
                ? state.values[objectKey]
                : inputType !== "checkbox"
                ? ""
                : false
            }
            onChange={(e) => {
              onChangeFormInput(
                objectKey,
                inputType !== "checkbox" ? e.target.value : e.target.checked,
                state,
                setState,
                validateFunction
              );
            }}
          />
          {language && <LanguageComponent />}
        </>
      ) : inputType === "textarea" ? (
        <>
          <textarea
            cols={30}
            rows={10}
            id={`form-input-${page}-${objectKey}}`}
            name={`form-input-${page}-${objectKey}}`}
            style={
              isError
                ? { border: "solid var(--red) 2px", outline: "var(--red)" }
                : {}
            }
            placeholder={`Merci de renseigner ${placeholder}`}
            value={state.values[objectKey] ? state.values[objectKey] : ""}
            onChange={(e) => {
              onChangeFormInput(
                objectKey,
                e.target.value,
                state,
                setState,
                validateFunction
              );
            }}
          />
          {language && <LanguageComponent />}
        </>
      ) : inputType === "picture" ? (
        <>
          <input
            id={`form-input-${page}-${objectKey}}`}
            name={`form-input-${page}-${objectKey}}`}
            type="file"
            multiple={multipleOption ? true : false}
            disabled={disableCondition ? true : false}
            onChange={(e) => {
              if (!multipleOption) {
                onChangeFormInput(
                  objectKey,
                  e.target.files[0],
                  state,
                  setState,
                  validateFunction
                );
              } else {
                onChangeFormInput(
                  objectKey,

                  Array.from(e.target.files),
                  state,
                  setState,
                  validateFunction
                );
              }
            }}
          />
          {state.values && state.values[objectKey] && (
            <PreviewPicture
              objectKey={objectKey}
              state={state}
              setState={setState}
              multiple={multipleOption ? true : false}
            />
          )}
        </>
      ) : inputType === "select" ? (
        <select
          id={`form-input-${page}-${objectKey}}`}
          name={`form-input-${page}-${objectKey}}`}
          style={isError ? { border: "solid red 2px" } : {}}
          value={
            state.values[objectKey] && state.values[objectKey]["_id"]
              ? state.values[objectKey]["_id"]
              : state.values[objectKey]
              ? state.values[objectKey]
              : ""
          }
          onChange={(e) => {
            e.preventDefault();
            onChangeFormInput(
              objectKey,
              e.target.value,
              state,
              setState,
              validateFunction
            );
          }}>
          <option value="">Merci de choisir {placeholder}</option>
          {arrayOptions &&
            arrayOptions.length > 0 &&
            arrayOptions.map((option) => {
              return (
                <option key={option["_id"]} value={option["_id"]}>
                  {option.name_fr}
                </option>
              );
            })}
        </select>
      ) : null}

      {state.touched[objectKey] && state.errors[objectKey] ? (
        <div className={"form-error-container"}>
          <p>{state.errors[objectKey]}</p>
        </div>
      ) : null}
    </div>
  );
};

export default InputComponent;
