import React from "react";

import IcoMoon from "react-icomoon";
import iconSet from "../../../assets/icons/selectionIcons.json";

const IconComponent = (props) => (
  <IcoMoon
    className={props.class ? props.class : ""}
    iconSet={iconSet}
    {...props}
  />
);

export default IconComponent;
