import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import TableDeleteUpdateComponent from "../../Shared/components/TableDeleteUpdateComponent/TableDeleteUpdateComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";

const UsersListe = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    informationMessage,
    setModale,
  } = useGlobalStatesContext();

  const [dataList, setDataList] = useState([]);

  const fetchDataList = async () => {
    setIsLoading(true);
    const responseDataList = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "user/list",
      ""
    );
    if (responseDataList && responseDataList.statut === "OK") {
      setDataList(responseDataList.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDataList();
  }, []);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="backoffice-list-main">
      <h1>Liste des users</h1>
      <div className="backoffice-list-container">
        <BackofficeGoBack collection={"utilisateurs"} />
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />

        <ModaleComponent goBack={true} userSession={userSession} />

        <Link
          className="backoffice-list-create-button"
          to={"/backoffice/users/create"}>
          Ajouter un utilisateur
        </Link>

        <div className="table-container">
          {dataList && dataList.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Email</th>

                  <th>Role</th>

                  <th>Date de création</th>
                </tr>
              </thead>
              <tbody>
                {dataList
                  .sort(
                    (a, b) =>
                      new Date(b.sending_date) - new Date(a.sending_date)
                  )
                  .map((item, index) => {
                    return (
                      <tr key={item["_id"]}>
                        <td>{item["email"] ? item["email"] : ""}</td>
                        <td>{item["role"] ? item["role"] : ""}</td>

                        <td>
                          {item["create_date"]
                            ? new Date(item["create_date"]).toLocaleDateString()
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <h2>Aucune demande d'information</h2>
          )}
        </div>
      </div>
    </main>
  );
};

export default UsersListe;
