import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import {
  requestHandle,
  sendDataToServer,
} from "../../Shared/Utils/RequestFunctions";

import InputComponent from "../../Shared/components/Form/InputComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";

import LanguageComponent from "../../Shared/components/LanguageComponent/LanguageComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";

const ContactDetailsUpdate = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    informationMessage,
    setInformationMessage,
    setModale,
  } = useGlobalStatesContext();

  const isSingleID = useLocation().pathname.split("/").pop();

  const [dataToSend, setDataToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });

  const validateContact = (state) => {
    const errorsObject = {};
    let isError = false;

    if (state.values) {
      if (state.values.robot_checkbox) {
        isError = true;
        setInformationMessage({
          title: "Unauthorized",
          content: "Unauthorized action",
        });
      }
      if (!state.values.last_name) {
        errorsObject.last_name = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.phone_number) {
        errorsObject.phone_number = "Merci de renseigner ce champ";
        isError = true;
      }

      if (
        state.values.email &&
        !state.values.email
          .toLowerCase()
          .match(
            /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
          )
      ) {
        errorsObject.email = "Merci de renseigner un email valide";
        isError = true;
      }

      if (!state.values.adress) {
        errorsObject.adress = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.post_code) {
        errorsObject.post_code = "Merci de renseigner ce champ";
        isError = true;
      }
      if (!state.values.message) {
        errorsObject.message = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.city) {
        errorsObject.city = "Merci de renseigner ce champ";
        isError = true;
      }

      const copyDataToSend = { ...dataToSend };
      copyDataToSend.errors = errorsObject;
      setDataToSend(copyDataToSend);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendServiceData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateContact(dataToSend)) {
      await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "PUT",
        "contact/backoffice",
        dataToSend.values,
        setModale,
        {
          title: "Succes",
          content: "Demande d'information mis à jour",
          collection: "contact",
          listeName: "demandes d'informations",
        }
      );
    } else {
      const keysObjectToSend = Object.keys(dataToSend.errors);

      let newTouchedObject = {};

      keysObjectToSend.forEach((key) => {
        newTouchedObject = { ...newTouchedObject, [`${key}`]: true };
      });

      const copyDataToSend = { ...dataToSend };
      copyDataToSend.touched = newTouchedObject;
      setDataToSend(copyDataToSend);

      setInformationMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
    setIsLoading(false);
  };

  const fetchSingleData = async () => {
    if (isSingleID !== "create") {
      const responseFetchSingleData = await requestHandle(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "POST",
        "contact/backoffice",
        { single: isSingleID }
      );
      if (responseFetchSingleData && responseFetchSingleData.statut === "OK") {
        setDataToSend((prevState) => {
          return {
            ...prevState,
            values: responseFetchSingleData.message,
          };
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleData();
  }, []);

  if (isLoading) return <LoadingComponent />;

  return (
    <main className="backoffice-form-main">
      <h1>
        {isSingleID === "create"
          ? "Créer un nouveau service"
          : "Mettre à jour un service"}
      </h1>
      <div className="backoffice-form-container">
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />

        <ModaleComponent goBack={true}>
          <LanguageComponent nameClass={true} />
          <form onSubmit={(e) => sendServiceData(e)}>
            <div className="input-container">
              <InputComponent
                inputType={"checkbox"}
                page={"backoffice-service"}
                label={""}
                objectKey={"robot_checkbox"}
                placeholder={""}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateContact}
              />

              {dataToSend && dataToSend.values && (
                <>
                  <InputComponent
                    inputType={"text"}
                    label={"Nom"}
                    objectKey={"last_name"}
                    placeholder={"Nom"}
                    isError={
                      dataToSend.errors.last_name &&
                      dataToSend.touched.last_name
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Prenom"}
                    objectKey={"first_name"}
                    placeholder={"Prenom"}
                    isError={
                      dataToSend.errors.first_name &&
                      dataToSend.touched.first_name
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Email"}
                    objectKey={"email"}
                    placeholder={"Email"}
                    isError={
                      dataToSend.errors.email && dataToSend.touched.email
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Numéro de téléphone"}
                    objectKey={"phone_number"}
                    placeholder={"Numéro de téléphone"}
                    isError={
                      dataToSend.errors.phone_number &&
                      dataToSend.touched.phone_number
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Adresse"}
                    objectKey={"adress"}
                    placeholder={"Adresse"}
                    isError={
                      dataToSend.errors.adress && dataToSend.touched.adress
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Code postal"}
                    objectKey={"post_code"}
                    placeholder={"Code postal"}
                    isError={
                      dataToSend.errors.post_code &&
                      dataToSend.touched.post_code
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Ville"}
                    objectKey={"city"}
                    placeholder={"Ville"}
                    isError={dataToSend.errors.city && dataToSend.touched.city}
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />
                  <InputComponent
                    inputType={"text"}
                    label={"Sujet"}
                    objectKey={"subject"}
                    placeholder={"Sujet"}
                    isError={
                      dataToSend.errors.subject && dataToSend.touched.subject
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"text"}
                    label={"Service"}
                    objectKey={"service"}
                    placeholder={"Service"}
                    isError={
                      dataToSend.errors.service && dataToSend.touched.service
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"textarea"}
                    label={"Message"}
                    objectKey={"message"}
                    placeholder={"Message"}
                    isError={
                      dataToSend.errors.message && dataToSend.touched.message
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />

                  <InputComponent
                    inputType={"textarea"}
                    label={"Notes"}
                    objectKey={"personal_notes"}
                    placeholder={"Notes"}
                    isError={
                      dataToSend.errors.personal_notes &&
                      dataToSend.touched.personal_notes
                    }
                    state={dataToSend}
                    setState={setDataToSend}
                    validateFunction={validateContact}
                  />
                </>
              )}
            </div>

            <button type="submit">Envoyer</button>
          </form>
        </ModaleComponent>
      </div>
    </main>
  );
};

export default ContactDetailsUpdate;
