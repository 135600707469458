import ReactPaginate from "react-paginate";
import React, { useEffect } from "react";

import Lottie from "react-lottie";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import { useGlobalStatesContext } from "../../Context/GlobalStates";

import headerLoadingLottie from "../../../assets/lottie/LoadingHeader.json";
import LanguageSwapOpacityWrapper from "../languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";

function PaginatedRealisation({
  itemsPerPage,
  items,
  filters,
  itemOffset,
  setItemOffset,
  pageCount,
  setPageCount,
  isLoading,
  currentItems,
  setCurrentItems,
}) {
  const navigate = useNavigate();
  const { languageWebSite } = useGlobalStatesContext();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: headerLoadingLottie,
  };

  const endOffset = itemOffset + itemsPerPage;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  useEffect(() => {
    setCurrentItems(items.slice(itemOffset, endOffset));

    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filters]);

  return (
    <>
      <div className="realisations-container">
        {isLoading ? (
          <div style={{ width: "100%", height: "25vh" }}>
            <Lottie
              style={{ margin: "0 auto" }}
              options={lottieOptions}
              height={180}
              width={180}
            />
          </div>
        ) : !isLoading && currentItems && currentItems.length > 0 ? (
          currentItems.map((realisation, index) => {
            return (
              <motion.article
                whileHover={{ scale: 1.05, cursor: "pointer" }}
                onClick={() => {
                  navigate(`/realisation/${realisation["_id"]}`);
                }}
                key={realisation["_id"]}
                className="home-realisation-card">
                {realisation["realisation_picture"] && (
                  <img src={realisation["realisation_picture"]} alt="" />
                )}
              </motion.article>
            );
          })
        ) : (
          <div>
            <h3>
              Nous n'avons pas encore partagé de réalisation dans cette
              catégorie, n'hésitez pas à nous en faire la demande 😉{" "}
            </h3>
          </div>
        )}
      </div>
      <LanguageSwapOpacityWrapper>
        <ReactPaginate
          forcePage={itemOffset}
          previousLabel={languageWebSite === "FR" ? "précédent" : "prev"}
          nextLabel={languageWebSite === "FR" ? "suivant" : "next"}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          // marginPagesDisplayed={2}
          pageCount={pageCount}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="realisation-list-pagination"
          activeClassName="pagination-active"
        />
      </LanguageSwapOpacityWrapper>
    </>
  );
}

export default PaginatedRealisation;
