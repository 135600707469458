import React, { useState, useEffect } from "react";

import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import TableDeleteUpdateComponent from "../../Shared/components/TableDeleteUpdateComponent/TableDeleteUpdateComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";

import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";

const NavigationListe = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    informationMessage,
    setModale,
  } = useGlobalStatesContext();

  const [dataList, setDataList] = useState([]);

  const fetchDataList = async () => {
    setIsLoading(true);
    const responseDataList = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "navigation",
      ""
    );
    if (responseDataList && responseDataList.statut === "OK") {
      setDataList(responseDataList.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDataList();
  }, []);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;
  return (
    <main id="backoffice-list-main">
      <h1>Liste des catégories de navigation</h1>
      <div className="backoffice-list-container">
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />

        <ModaleComponent goBack={true} userSession={userSession} />

        <div className="table-container">
          {dataList && dataList.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Nom français page</th>
                  <th>Nom anglais page</th>

                  <th>Modifier</th>
                  <th>Désactiver</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((item, index) => {
                  return (
                    <tr key={item["_id"]}>
                      <td>{item["name_fr"] ? item["name_fr"] : ""}</td>
                      <td>{item["name_en"] ? item["name_en"] : ""}</td>

                      <TableDeleteUpdateComponent
                        role={userSession.role}
                        restrictDelete={true}
                        setIsLoading={setIsLoading}
                        setInformationMessage={setInformationMessage}
                        setModale={setModale}
                        setDataList={setDataList}
                        dataList={dataList}
                        token={userSession.token}
                        index={index}
                        collection={"navigation"}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h2>Ajoutez une page pour la voir apparaitre ici</h2>
          )}
        </div>
      </div>
    </main>
  );
};

export default NavigationListe;
