import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import {
  requestHandle,
  sendDataToServer,
} from "../../Shared/Utils/RequestFunctions";

import InputComponent from "../../Shared/components/Form/InputComponent";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";

import LanguageComponent from "../../Shared/components/LanguageComponent/LanguageComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import BackofficeGoBack from "../../Shared/components/BackofficeGoBack/BackofficeGoBack";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";
import CkEditorComponent from "../../Shared/components/CkEditor/CkEditorComponent";

const ServiceCreateUpdate = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    informationMessage,
    setInformationMessage,
    setModale,
    languageInput,
  } = useGlobalStatesContext();

  const isSingleID = useLocation().pathname.split("/").pop();

  const [dataToSend, setDataToSend] = useState({
    values: {},
    touched: {},
    errors: {},
  });
  const [sectionToSelect, setSectionToSelect] = useState([]);

  const validateForm = (state) => {
    const errorsObject = {};
    let isError = false;

    if (state.values) {
      if (!state.values.name_fr) {
        errorsObject.name_fr = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.content_fr) {
        errorsObject.content_fr = "Merci de renseigner ce champ";
        isError = true;
      }
      const copyNavigationData = { ...dataToSend };
      copyNavigationData.errors = errorsObject;
      setDataToSend(copyNavigationData);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendServiceData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateForm(dataToSend)) {
      dataToSend.values["ref_to_push"] =
        dataToSend.values["associated_section"];

      dataToSend.values["slug"] = `${dataToSend.values.name_fr
        .toLowerCase()
        .split(" ")
        .filter((elmt) => elmt !== "/")
        .join("-")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")}`;

      await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        isSingleID !== "create" ? "PUT" : "POST",
        "service",
        dataToSend.values,
        setModale,
        {
          title: isSingleID ? "Service mis à jour" : "Nouveau service ajouté",
          content: "Un nouveau service a été ajouté à la liste",
          collection: "service",
          listeName: "services",
        }
      );
    } else {
      setInformationMessage({
        title: "Erreur formulaire",
        content: "Erreur de validation du formulaire",
      });
    }
    setIsLoading(false);
  };

  const fetchSingleData = async () => {
    if (isSingleID !== "create") {
      const responseFetchSingleData = await requestHandle(
        setIsLoading,
        setInformationMessage,
        userSession.token,
        "POST",
        "service",
        { single: isSingleID }
      );
      if (responseFetchSingleData && responseFetchSingleData.statut === "OK") {
        const responseObject = responseFetchSingleData.message;

        if (responseFetchSingleData.message["gallery"]) {
          responseObject["galleryPictures"] =
            responseFetchSingleData.message["gallery"].split(",");
        }

        setDataToSend((prevState) => {
          return {
            ...prevState,
            values: responseFetchSingleData.message,
          };
        });
      }
      setIsLoading(false);
    }
  };

  const fetchSectionToSelect = async () => {
    setIsLoading(true);
    const responseCategory = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "section"
      // ""
    );
    if (responseCategory && responseCategory.statut === "OK") {
      setSectionToSelect(responseCategory.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSingleData();
    fetchSectionToSelect();
  }, []);

  if (isLoading) return <LoadingComponent />;

  return (
    <main className="backoffice-form-main">
      <h1>
        {isSingleID === "create"
          ? "Créer un nouveau service"
          : "Mettre à jour un service"}
      </h1>
      <div className="backoffice-form-container">
        <InformationMessageComponent
          informationMessage={informationMessage}
          setInformationMessage={setInformationMessage}
        />

        <ModaleComponent goBack={true}>
          <LanguageComponent nameClass={true} />
          <form onSubmit={(e) => sendServiceData(e)}>
            <div className="input-container">
              <InputComponent
                inputType={"checkbox"}
                page={"backoffice-service"}
                label={""}
                objectKey={"robot_checkbox"}
                placeholder={""}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateForm}
              />

              <InputComponent
                inputType={"select"}
                isError={
                  dataToSend.errors["associated_section"]
                    ? dataToSend.errors["associated_section"]
                    : false
                }
                page={"backoffice-service"}
                label={`Nom de la section associée`}
                objectKey={"associated_section"}
                placeholder={`le nom de la section associée`}
                state={dataToSend}
                setState={setDataToSend}
                validateFunction={validateForm}
                arrayOptions={
                  sectionToSelect
                    ? sectionToSelect.filter(
                        (elmt) =>
                          elmt.statut === true &&
                          elmt["name_fr"] !== "Qui sommes nous"
                      )
                    : []
                }
              />

              {dataToSend &&
                dataToSend.values &&
                dataToSend.values.associated_section && (
                  <>
                    <InputComponent
                      inputType={"text"}
                      required={languageInput === "FR" ? true : false}
                      isError={
                        languageInput === "FR" &&
                        dataToSend.errors.name_fr &&
                        dataToSend.touched.name_fr
                          ? dataToSend.errors.name_fr
                          : dataToSend.errors.name_en &&
                            dataToSend.touched.name_en &&
                            dataToSend.errors.name_en
                      }
                      page={"backoffice-service"}
                      objectKey={languageInput === "FR" ? "name_fr" : "name_en"}
                      label={`Nom dans la barre de navigation ${
                        languageInput === "FR" ? "français" : "anglais"
                      } du service`}
                      placeholder={`le nom dans la barre de navigation ${
                        languageInput === "FR" ? "français" : "anglais"
                      }  du service`}
                      state={dataToSend}
                      setState={setDataToSend}
                      validateFunction={validateForm}
                    />

                    <InputComponent
                      inputType={"text"}
                      isError={
                        languageInput === "FR" &&
                        dataToSend.errors.title_fr &&
                        dataToSend.touched.title_fr
                          ? dataToSend.errors.title_fr
                          : dataToSend.errors.title_en &&
                            dataToSend.touched.title_en &&
                            dataToSend.errors.title_en
                      }
                      page={"backoffice-service"}
                      objectKey={
                        languageInput === "FR" ? "title_fr" : "title_en"
                      }
                      label={`Titre ${
                        languageInput === "FR" ? "français" : "anglais"
                      } du service`}
                      placeholder={`le titre ${
                        languageInput === "FR" ? "français" : "anglais"
                      }  du service`}
                      state={dataToSend}
                      setState={setDataToSend}
                      validateFunction={validateForm}
                    />

                    <InputComponent
                      inputType={"checkbox"}
                      page={"backoffice-service"}
                      label={"Bouton demande d'information sur le service"}
                      objectKey={"has_button"}
                      placeholder={""}
                      state={dataToSend}
                      setState={setDataToSend}
                      validateFunction={validateForm}
                    />

                    <CkEditorComponent
                      required={languageInput === "FR" ? true : false}
                      page={"backoffice-service"}
                      label={`Contenu ${
                        languageInput === "FR" ? "français" : "anglais"
                      } du service`}
                      state={dataToSend}
                      setState={setDataToSend}
                      objectKey={
                        languageInput === "FR" ? "content_fr" : "content_en"
                      }
                    />

                    <InputComponent
                      inputType={"picture"}
                      page={"backoffice-service"}
                      label={"Photo de présentation du service"}
                      objectKey={"service_picture"}
                      state={dataToSend}
                      setState={setDataToSend}
                      validateFunction={validateForm}
                    />
                  </>
                )}
            </div>

            <button type="submit">Envoyer</button>
          </form>
        </ModaleComponent>
      </div>
    </main>
  );
};

export default ServiceCreateUpdate;
