import React from "react";

import { Link } from "react-router-dom";

import FooterMap from "./components/FooterMap";

import { useGlobalStatesContext } from "../../Context/GlobalStates";

import NavigationComponent from "../Navigation/NavigationComponent";
import LanguageSwapOpacityWrapper from "../languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";
import IconComponent from "../IconComponent/IconComponent";

const Footer = () => {
  const { languageWebSite } = useGlobalStatesContext();

  return (
    <footer className="footer">
      <div className="upper-footer">
        <div className="openning-hours">
          <LanguageSwapOpacityWrapper>
            <h2>
              {languageWebSite === "FR" ? "Nos horaires" : "Opening Hours"}
            </h2>
          </LanguageSwapOpacityWrapper>

          {languageWebSite === "FR" ? (
            <LanguageSwapOpacityWrapper>
              <p>
                Ouvert du lundi au vendredi
                <br />
                de 9h00 à 12h et de 13h30 à 17h30
                <br />
                et le samedi de 9h à 12h
                <br />
                (uniquement sur rendez-vous).
              </p>
            </LanguageSwapOpacityWrapper>
          ) : (
            <LanguageSwapOpacityWrapper>
              <p>
                Open from Monday to Friday
                <br />
                from 9:00 AM to 12:00 PM and from 1:30 PM to 5:30 PM
                <br />
                and on Saturday from 9:00 AM to 12:00 PM
                <br />
                (by appointment only).
              </p>
            </LanguageSwapOpacityWrapper>
          )}
        </div>
        <div className="footer-contact">
          <LanguageSwapOpacityWrapper>
            <h2>
              {languageWebSite === "FR" ? "Nous contacter" : "Contact us"}
            </h2>
          </LanguageSwapOpacityWrapper>

          <ul>
            <li>700 Avenue Alfred Court</li>
            <li>Miramont-de-Guyenne, France</li>
            <li>
              <a href="tel:+33553880955">05 53 88 09 55</a>
            </li>
            <li>
              <a href="mailto:enriquez@outlook.fr">enriquez@outlook.fr</a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/ENRIQUEZFERBOEUF"
                target="_blank">
                <IconComponent
                  icon="facebook"
                  size={20}
                  color="var(--white)"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </li>
          </ul>
        </div>
        <FooterMap />
      </div>
      <div className="sitemap">
        <LanguageSwapOpacityWrapper>
          <h2>{languageWebSite === "FR" ? "Plan du site" : "Site map"}</h2>
        </LanguageSwapOpacityWrapper>

        <NavigationComponent nameClass={"footer"}>
          <LanguageSwapOpacityWrapper>
            <li>
              <Link to={"/contact"}>
                {languageWebSite === "FR" ? "Nous contacter" : "Contact us"}{" "}
              </Link>
            </li>
          </LanguageSwapOpacityWrapper>
        </NavigationComponent>
      </div>
      <div className="lower-footer">
        <LanguageSwapOpacityWrapper>
          <Link to={"/termsandcondition"}>
            {languageWebSite === "FR"
              ? "Conditions générales"
              : "General conditions"}{" "}
          </Link>
        </LanguageSwapOpacityWrapper>

        <p>
          Site conçu et réalisé par{" "}
          <a
            href="http://centcommentaire.fr"
            target="_blank"
            rel="noreferrer"
            id="centcommentaire">
            Cent Commentaire
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
