import "./App.css";
import React, { useState } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Cookies from "js-cookie";

import { HelmetProvider } from "react-helmet-async";

// IMPORT ROUTES
import ClientRoutes from "./Router/ClientRoutes";
import BackofficeRoutes from "./Router/BackofficeRoutes";

// IMPORT UTILS

import ScrollToTop from "./Shared/Utils/ScrollToTop";

function App() {
  const [userSession, setUserSession] = useState(
    Cookies.get("session_enriquez_service")
      ? JSON.parse(Cookies.get("session_enriquez_service"))
      : null
  );

  const setUser = async (token, role) => {
    if (token) {
      Cookies.set(
        "session_enriquez_service",
        JSON.stringify({ token, role: role ? role : "" }),
        {
          expires: 2,
        }
      );
      setUserSession({ token, role: role ? role : "" });
    } else {
      Cookies.remove("session_enriquez_service");
      setUserSession(null);
    }
  };

  return (
    <HelmetProvider>
      <Router basename="/">
        <ScrollToTop />
        <Routes>
          <Route
            path="/*"
            element={
              <ClientRoutes setUser={setUser} userSession={userSession} />
            }
          />

          <Route
            path="/backoffice/*"
            element={
              <BackofficeRoutes userSession={userSession} setUser={setUser} />
            }
          />

          {/* {!userSession && (
            <Route path="*" element={<Navigate to="/" replace />} />
          )} */}
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
