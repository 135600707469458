import React from "react";

import { useNavigate } from "react-router-dom";

// IMPORT SHARED COMPONENT
import InputComponent from "../../../Shared/components/Form/InputComponent";

// IMPORT REQUEST FUNCTIONS
import { sendDataToServer } from "../../../Shared/Utils/RequestFunctions";

import { useGlobalStatesContext } from "../../../Shared/Context/GlobalStates";

const SignupForm = ({
  authDataToSend,
  setAuthDataToSend,
  setUser,
  fromList,
}) => {
  const navigate = useNavigate();
  const { setIsLoading, setInformationMessage, setModale } =
    useGlobalStatesContext();

  const validateSignup = (state) => {
    const errorsObject = {};
    let isError = false;
    if (state.values) {
      if (state.values.robot_checkbox) {
        isError = true;
        // errorsObject.robot_checkbox = "HACKING";
      }
      if (!state.values.signup_email) {
        errorsObject.signup_email = "Merci de renseigner ce champ";
        isError = true;
      }

      if (
        state.values.signup_email &&
        !state.values.signup_email
          .toLowerCase()
          .match(
            /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
          )
      ) {
        errorsObject.signup_email = "Merci de renseigner un email valide";
        isError = true;
      }

      if (!state.values.checkpassword) {
        errorsObject.checkpassword = "Merci de renseigner ce champ";
        isError = true;
      }

      if (!state.values.signup_password) {
        errorsObject.signup_password = "Merci de renseigner ce champ";
        isError = true;
      }

      if (
        state.values.checkpassword &&
        state.values.checkpassword &&
        state.values.signup_password &&
        state.values.checkpassword !== state.values.signup_password
      ) {
        errorsObject.checkpassword = "Vos mots de passe ne sont pas identiques";
        errorsObject.signup_password =
          "Vos mots de passe ne sont pas identiques";
        isError = true;
      }

      const copyAuthData = { ...authDataToSend };
      copyAuthData.errors = errorsObject;
      setAuthDataToSend(copyAuthData);
    } else {
      isError = true;
    }

    return isError;
  };

  const sendSignup = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (!validateSignup(authDataToSend)) {
      const responseSignup = await sendDataToServer(
        setIsLoading,
        setInformationMessage,
        "",
        "POST",
        "user/signup",
        authDataToSend.values,
        setModale,
        {}
      );
      if (responseSignup && responseSignup.statut === "OK") {
        if (!fromList) {
          setUser(
            responseSignup.message["token"],
            responseSignup.message["role"] ? responseSignup.message["role"] : ""
          );
        }

        navigate("/backoffice/users/list");
      }
    } else {
      return setInformationMessage({
        title: "Erreur formulaire",
      });
    }
    setIsLoading(false);
  };
  return (
    <form onSubmit={(e) => sendSignup(e)}>
      <InputComponent
        inputType={"checkbox"}
        page={"auth"}
        label={""}
        objectKey={"robot_checkbox"}
        placeholder={""}
        state={authDataToSend}
        setState={setAuthDataToSend}
        validateFunction={validateSignup}
      />
      <InputComponent
        inputType={"email"}
        page={"auth"}
        label={"Email"}
        objectKey={"signup_email"}
        placeholder={"un email"}
        isError={
          authDataToSend.errors.signup_email &&
          authDataToSend.touched.signup_email
        }
        state={authDataToSend}
        setState={setAuthDataToSend}
        validateFunction={validateSignup}
      />

      <InputComponent
        inputType={"password"}
        page={"auth"}
        label={"Mot de passe"}
        objectKey={"signup_password"}
        placeholder={"un mot de passe"}
        isError={
          authDataToSend.errors.signup_password &&
          authDataToSend.touched.signup_password
        }
        state={authDataToSend}
        setState={setAuthDataToSend}
        validateFunction={validateSignup}
      />

      <InputComponent
        inputType={"password"}
        page={"auth"}
        isError={
          authDataToSend.errors.checkpassword &&
          authDataToSend.touched.checkpassword
        }
        label={"Confirmation de mot de passe"}
        objectKey={"checkpassword"}
        placeholder={"une confirmation de mot de passe"}
        state={authDataToSend}
        setState={setAuthDataToSend}
        validateFunction={validateSignup}
      />

      <button type="submit">Envoyer</button>
    </form>
  );
};

export default SignupForm;
