import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";

import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import TableDeleteUpdateComponent from "../../Shared/components/TableDeleteUpdateComponent/TableDeleteUpdateComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";

const ServiceListe = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    informationMessage,
    setModale,
  } = useGlobalStatesContext();

  const [dataList, setDataList] = useState([]);

  const fetchDataList = async () => {
    setIsLoading(true);
    const responseDataList = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "service",
      ""
    );
    if (responseDataList && responseDataList.statut === "OK") {
      setDataList(responseDataList.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataList();
  }, []);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="backoffice-list-main">
      <h1>Liste des services</h1>
      <div className="backoffice-list-container">
        {informationMessage && (
          <InformationMessageComponent
            informationMessage={informationMessage}
            setInformationMessage={setInformationMessage}
          />
        )}

        <ModaleComponent goBack={true} userSession={userSession} />

        <Link
          className="backoffice-list-create-button"
          to={"/backoffice/service/create"}>
          Ajouter un service
        </Link>

        <div className="table-container">
          {dataList && dataList.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Section associée</th>

                  <th>Titre français service</th>
                  <th>Titre anglais service</th>

                  <th>Modifier</th>
                  <th>Désactiver</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((item, index) => {
                  return (
                    <tr key={item["_id"]}>
                      <td>
                        {item["associated_section"] &&
                        item["associated_section"]["name_fr"]
                          ? item["associated_section"]["name_fr"]
                          : ""}
                      </td>

                      <td>{item["title_fr"] ? item["title_fr"] : ""}</td>
                      <td>{item["title_en"] ? item["title_en"] : ""}</td>

                      <TableDeleteUpdateComponent
                        setIsLoading={setIsLoading}
                        setInformationMessage={setInformationMessage}
                        setModale={setModale}
                        setDataList={setDataList}
                        dataList={dataList}
                        token={userSession.token}
                        index={index}
                        collection={"service"}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h2>Ajoutez un service pour la voir apparaitre ici</h2>
          )}
        </div>
      </div>
    </main>
  );
};

export default ServiceListe;
