import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { requestHandle } from "../../Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Context/GlobalStates";
import { AnimatePresence, motion } from "framer-motion";

import IconComponent from "../IconComponent/IconComponent";

import headerLoadingLottie from "../../../assets/lottie/LoadingHeader.json";
import Lottie from "react-lottie";

const NavigationComponent = ({ nameClass, children }) => {
  const {
    setIsLoading,
    setInformationMessage,
    languageWebSite,
    innerWidthState,
    modalSideMenu,
    setModalSideMenu,
    refreshLanguageOpacity,
  } = useGlobalStatesContext();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: headerLoadingLottie,
  };

  const navigate = useNavigate();

  const closeFilledVariants = {
    closed: { opacity: 0 },
    open: { opacity: 1 },
  };

  const placeHolderVariant =
    nameClass === "header"
      ? {
          closed: {},
          open: {
            width: "100%",
            height: "40%",
          },
        }
      : {
          closed: {},
          open: {},
        };

  const topCategorySlideVariants =
    nameClass === "header"
      ? {
          closed: {
            x: "-120%",
            opacity: 1,
            backgroundColor: "#fff",
            position: "absolute",
            top: "0",
            left: "0",
            width: "0%",
            height: "0%",
            overflow: "hidden",
          },
          open: {
            x: 0,
            opacity: 1,
            zIndex: 1000,
            backgroundColor: "#fff",

            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "120vh",
            overflow: "scroll",
          },
        }
      : {
          closed: {},
          open: {},
        };

  const subCategorySlideVariants =
    nameClass === "header" && innerWidthState <= 768
      ? {
          closed: {
            height: 0,
            overflowY: "scroll",
          },
          open: { height: "auto", width: "80%" },
        }
      : nameClass === "header" && innerWidthState > 768
      ? {
          closed: {
            height: 0,
            opacity: 0,
          },
          open: {
            height: "auto",

            opacity: 1,
            width: "20%",
          },
        }
      : {
          closed: {},
          open: {},
        };

  const [isHeaderLoading, setIsHeaderLoading] = useState(true);

  const [category, setCategory] = useState([]);
  const [headerSubCategoryIndexToDisplay, setHeaderSubCategoryIndexToDisplay] =
    useState();

  const textToFadeVariants = {
    fade: {
      opacity: refreshLanguageOpacity,
    },
  };

  const ulToStopDisplayRef = useRef(null);

  const fetchNavigationData = async () => {
    const responseNavigationData = await requestHandle(
      setIsLoading,
      setInformationMessage,
      "",
      "GET",
      "client/navigation",
      ""
    );
    if (responseNavigationData && responseNavigationData.statut === "OK") {
      setCategory(responseNavigationData.message);
    }
    setIsHeaderLoading(false);
  };
  useEffect(() => {
    fetchNavigationData();
  }, []);

  useEffect(() => {
    if (!modalSideMenu) {
      setHeaderSubCategoryIndexToDisplay();
    }
  }, [modalSideMenu]);

  useEffect(() => {
    modalSideMenu && innerWidthState < 768
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [modalSideMenu]);

  return (
    <AnimatePresence initial={false}>
      <motion.nav
        onMouseLeave={() => {
          if (innerWidthState >= 968) {
            setHeaderSubCategoryIndexToDisplay();
          }
        }}
        animate={modalSideMenu || innerWidthState > 768 ? "open" : "closed"}
        variants={
          innerWidthState > 768 ? placeHolderVariant : topCategorySlideVariants
        }
        transition={
          innerWidthState < 768 ? { duration: 0.8 } : { duration: 0.1 }
        }
        className={`${nameClass}-navigation-container`}>
        {innerWidthState < 768 && nameClass === "header" && (
          <motion.div
            animate={modalSideMenu ? "open" : "closed"}
            variants={closeFilledVariants}
            transition={{ duration: 0.8 }}
            className="close-filled-container">
            <IconComponent
              icon="close-filled"
              size={20}
              color="rgb(36, 36, 36)"
              style={{ cursor: "pointer" }}
              onClick={() => setModalSideMenu(false)}
            />
          </motion.div>
        )}
        <motion.ul
          animate={
            (nameClass === "header" && modalSideMenu) ||
            (nameClass === "header" && innerWidthState > 968)
              ? { opacity: 1 }
              : nameClass === "footer"
              ? { opacity: 1 }
              : { opacity: 0 }
          }
          transition={{ duration: 0.8 }}
          className={`nav-ul-container ${
            nameClass === "footer" ? "footer-" : ""
          }top-category-ul`}>
          {isHeaderLoading ? (
            <div style={{ width: "100%" }}>
              <Lottie
                style={{ margin: "0 auto" }}
                options={lottieOptions}
                height={80}
                width={80}
              />
            </div>
          ) : (
            <>
              {category &&
                category.length > 0 &&
                category.map((item, index) => {
                  return (
                    <motion.li
                      key={`${nameClass}-${item["_id"]}`}
                      onClick={() => {
                        if (innerWidthState < 968) {
                          if (
                            nameClass === "header" &&
                            headerSubCategoryIndexToDisplay !== index
                          ) {
                            setHeaderSubCategoryIndexToDisplay(index);
                          } else if (
                            nameClass === "header" &&
                            headerSubCategoryIndexToDisplay === index
                          ) {
                            setHeaderSubCategoryIndexToDisplay();
                          } else {
                            navigate(`/page/${item["slug"]}`);
                          }
                        }
                      }}
                      onMouseEnter={() => {
                        if (innerWidthState >= 968) {
                          setHeaderSubCategoryIndexToDisplay(index);
                        }
                      }}>
                      {innerWidthState < 968 && nameClass === "header" ? (
                        <motion.p
                          whileHover={{
                            cursor: "pointer",
                            scale: 1.1,
                          }}
                          onClick={() => {
                            if (item["slug"] === "accueil") {
                              navigate("/");
                              if (innerWidthState < 768) {
                                setModalSideMenu(false);
                              }
                            }
                          }}
                          animate={"fade"}
                          variants={textToFadeVariants}
                          transition={{ duration: 0.2 }}>
                          {languageWebSite === "FR"
                            ? item.name_fr
                            : item.name_en
                            ? item.name_en
                            : item.name_fr}
                        </motion.p>
                      ) : innerWidthState >= 968 && nameClass === "header" ? (
                        <Link
                          to={`${
                            item["slug"] !== "accueil"
                              ? `/page/${item["slug"]}`
                              : "/"
                          }`}
                          state={item["_id"]}>
                          {languageWebSite === "FR"
                            ? item.name_fr
                            : item.name_en
                            ? item.name_en
                            : item.name_fr}
                        </Link>
                      ) : (
                        nameClass === "footer" && (
                          <motion.div
                            whileHover={{ backgroundColor: "var(--red)" }}
                            className={
                              nameClass === "footer"
                                ? "footer-link-container"
                                : ""
                            }
                            animate={"fade"}
                            variants={textToFadeVariants}
                            transition={{ duration: 0.2 }}>
                            <Link
                              to={`${
                                item["slug"] !== "accueil"
                                  ? `/page/${item["slug"]}`
                                  : "/"
                              }`}
                              state={item["_id"]}>
                              {languageWebSite === "FR"
                                ? item.name_fr
                                : item.name_en
                                ? item.name_en
                                : item.name_fr}
                            </Link>
                          </motion.div>
                        )
                      )}
                      {item.sections && item.sections.length > 0 && (
                        <AnimatePresence>
                          <motion.ul
                            ref={ulToStopDisplayRef}
                            className={
                              nameClass === "header"
                                ? "sub-category-ul"
                                : "footer-sub-category-ul"
                            }
                            animate={
                              modalSideMenu &&
                              headerSubCategoryIndexToDisplay &&
                              headerSubCategoryIndexToDisplay === index
                                ? "open"
                                : "closed"
                            }
                            variants={subCategorySlideVariants}
                            transition={{ duration: 0.4 }}>
                            {item.sections.map((section) => {
                              return (
                                <motion.li
                                  key={`${nameClass}-${section["_id"]}`}
                                  className={`${nameClass}-section-container`}>
                                  {/* {nameClass === "header" && (
                                    <div className="title-section-container">
                                     
                                      {section["name_fr"] && (
                                        <p>
                                          {languageWebSite === "FR"
                                            ? section["name_fr"]
                                            : section["name_en"]
                                            ? section["name_en"]
                                            : section["name_fr"]}
                                        </p>
                                      )}
                                    </div>
                                  )} */}
                                  {section["services"] &&
                                    section["services"].length > 0 && (
                                      <ul
                                        className={`${nameClass}-services-ul`}>
                                        {section["services"].map(
                                          (service, index) => {
                                            return (
                                              <Link
                                                key={`${nameClass}-${service["_id"]}`}
                                                to={`/page/${item["slug"]}#${service["slug"]}`}
                                                state={item["_id"]}>
                                                <motion.li
                                                  whileHover={{
                                                    backgroundColor:
                                                      "var(--red)",
                                                    color: "#fff",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    if (innerWidthState < 768) {
                                                      setModalSideMenu(false);
                                                    }
                                                  }}
                                                  animate={"fade"}
                                                  variants={textToFadeVariants}
                                                  transition={{
                                                    duration: 0.2,
                                                  }}>
                                                  {languageWebSite === "FR"
                                                    ? service.name_fr
                                                    : service.name_en
                                                    ? service.name_en
                                                    : service.name_fr}
                                                </motion.li>
                                              </Link>
                                            );
                                          }
                                        )}
                                      </ul>
                                    )}
                                </motion.li>
                              );
                            })}
                          </motion.ul>
                        </AnimatePresence>
                      )}
                    </motion.li>
                  );
                })}
              {children}
            </>
          )}
        </motion.ul>
      </motion.nav>
    </AnimatePresence>
  );
};

export default NavigationComponent;
