import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { requestHandle } from "../../Shared/Utils/RequestFunctions";
import { useGlobalStatesContext } from "../../Shared/Context/GlobalStates";
import InformationMessageComponent from "../../Shared/components/InformationMessageComponent/InformationMessageComponent";
import TableDeleteUpdateComponent from "../../Shared/components/TableDeleteUpdateComponent/TableDeleteUpdateComponent";
import ModaleComponent from "../../Shared/components/Modales/ModaleComponent";
import LoadingComponent from "../../Shared/components/LoadingComponent/LoadingComponent";

const SectionList = ({ userSession }) => {
  const {
    isLoading,
    setIsLoading,
    setInformationMessage,
    informationMessage,
    setModale,
  } = useGlobalStatesContext();

  const [dataList, setDataList] = useState([]);

  const fetchDataList = async () => {
    setIsLoading(true);
    const responseDataList = await requestHandle(
      setIsLoading,
      setInformationMessage,
      userSession.token,
      "GET",
      "section",
      ""
    );
    if (responseDataList && responseDataList.statut === "OK") {
      setDataList(responseDataList.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchDataList();
  }, []);

  if (isLoading) return <LoadingComponent isLoading={isLoading} />;

  return (
    <main id="backoffice-list-main">
      <h1>Liste des ensembles de services</h1>

      <div className="backoffice-list-container">
        {informationMessage && (
          <InformationMessageComponent
            informationMessage={informationMessage}
            setInformationMessage={setInformationMessage}
          />
        )}

        <ModaleComponent goBack={true} userSession={userSession} />

        <Link
          className="backoffice-list-create-button"
          to={"/backoffice/section/create"}>
          Ajouter un ensemble de services
        </Link>

        <div className="table-container">
          {dataList && dataList.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Page associée</th>

                  <th>Nom français ensemble de services</th>
                  <th>Nom anglais ensemble de services</th>

                  <th>Modifier</th>
                  <th>Désactiver</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {dataList
                  // .sort((a, b) => a["associated_page"] - b["associated_page"])
                  .map((item, index) => {
                    return (
                      <tr key={item["_id"]}>
                        <td>
                          {item["associated_page"] &&
                          item["associated_page"]["name_fr"]
                            ? item["associated_page"]["name_fr"]
                            : ""}
                        </td>

                        <td>{item["name_fr"] ? item["name_fr"] : ""}</td>
                        <td>{item["name_en"] ? item["name_en"] : ""}</td>

                        <TableDeleteUpdateComponent
                          setIsLoading={setIsLoading}
                          setInformationMessage={setInformationMessage}
                          setModale={setModale}
                          setDataList={setDataList}
                          dataList={dataList}
                          token={userSession.token}
                          index={index}
                          collection={"section"}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) : (
            <h2>
              Ajoutez une ensemble de services pour la voir apparaitre ici
            </h2>
          )}
        </div>
      </div>
    </main>
  );
};

export default SectionList;
