import React, { useState, useRef } from "react";

import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";

import desktopHeaderLogoServices from "../../../assets/img/enriquez-services-tr.png";

import croppedLogo from "../../../assets/img/logo-phone.png";
import desktopHeaderLogoFerboeuf from "../../../assets/img/enriquez-ferboeuf-tr.png";

import { Link, useNavigate, useLocation } from "react-router-dom";

import LanguageComponent from "../LanguageComponent/LanguageComponent";
import LanguageSwapOpacityWrapper from "../languageSwapOpacityWrapper/LanguageSwapOpacityWrapper";
import NavigationComponent from "../Navigation/NavigationComponent";
import IconComponent from "../IconComponent/IconComponent";
import { useGlobalStatesContext } from "../../Context/GlobalStates";

const Header = ({ userSession, setUser }) => {
  const navigate = useNavigate();

  const {
    innerWidthState,
    modalSideMenu,
    setModalSideMenu,
    setOpacityState,
    languageWebSite,
  } = useGlobalStatesContext();

  const { pathname } = useLocation();

  const burgerSlideVariants = {
    closed: { x: 0, opacity: 1 },
    open: { x: "90%", opacity: 0 },
  };

  const headerVariants = {
    fixed: {
      position: "sticky",
      top: 0,
      zIndex: 10,
      opacity: 1,
      y: 0,
    },

    absolute: {
      position: "sticky",
      top: 0,
      zIndex: 10,
      y: "-100%",
      opacity: 1,
    },
  };

  const headerRef = useRef(null);

  const { scrollY } = useScroll();

  const [viewIsTop, setViewIsTop] = useState(true);
  const [stickyState, setStickyState] = useState({
    y: 0,
    scrollDisplay: false,
  });

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest < stickyState["y"]) {
      setStickyState({
        y: latest,
        scrollDisplay: true,
      });
      setOpacityState(1);
    } else {
      setStickyState({
        y: latest,
        scrollDisplay: false,
      });
      setOpacityState(0);
    }

    if (scrollY.current === 0) {
      setViewIsTop(true);
      setOpacityState(1);
    } else {
      setViewIsTop(false);
    }
  });

  return (
    <AnimatePresence initial={false}>
      <motion.header
        animate={
          stickyState["scrollDisplay"] || viewIsTop ? "fixed" : "absolute"
        }
        exit={{ opacity: 0 }}
        variants={headerVariants}
        transition={{ duration: 0.4 }}
        ref={headerRef}>
        <div className="top-header-container">
          <LanguageSwapOpacityWrapper nameClass={"contact-link-container"}>
            {userSession ? (
              <div className="admin-navigation-container">
                <button
                  className="admin-navigation-button"
                  onClick={() => {
                    navigate("/backoffice/home");
                  }}>
                  Backoffice
                </button>
                <button
                  className="admin-navigation-button deconnexion-button"
                  onClick={() => {
                    setUser(null);
                    navigate("/");
                  }}>
                  Déconnexion
                </button>
              </div>
            ) : (
              <>
                <IconComponent
                  icon="mail"
                  size={30}
                  color="rgb(36, 36, 36)"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/contact")}
                />
                <Link to={"/contact"}>
                  {languageWebSite === "FR" ? "Nous contacter" : "Contact Us"}
                </Link>
              </>
            )}
          </LanguageSwapOpacityWrapper>

          <LanguageComponent webSite={true} />
        </div>
        <div className="bottom-header-container" id="bottom-header-container">
          <div className="logo-container">
            <img
              src={
                innerWidthState < 768
                  ? croppedLogo
                  : pathname.includes("elect")
                  ? desktopHeaderLogoFerboeuf
                  : desktopHeaderLogoServices
              }
              alt=""
              onClick={() => navigate("/")}
            />
          </div>

          {innerWidthState < 768 && (
            <AnimatePresence>
              <motion.div
                className="burger-nav-container"
                animate={modalSideMenu ? "open" : "closed"}
                variants={burgerSlideVariants}
                transition={{ duration: 0.8 }}>
                <IconComponent
                  icon="hamburger"
                  size={30}
                  color="rgb(36, 36, 36)"
                  style={{ cursor: "pointer" }}
                  onClick={() => setModalSideMenu((prevState) => !prevState)}
                />
              </motion.div>
            </AnimatePresence>
          )}

          <NavigationComponent nameClass={"header"}>
            <li id="link-to-enriquezmenager">
              <a href="https://enriquezmenager.fr/" target="_blank">
                {languageWebSite === "FR"
                  ? "Électroménager"
                  : "Home appliances"}
              </a>
            </li>
            <li>
              <Link
                to={"/realisations"}
                onClick={() => {
                  if (innerWidthState < 768) {
                    setModalSideMenu(false);
                  }
                }}>
                {languageWebSite === "FR" ? "Nos réalisations" : "Projects"}
              </Link>
            </li>
          </NavigationComponent>
        </div>
      </motion.header>
    </AnimatePresence>
  );
};

export default Header;
